import { DateTimePicker } from '@/components/form/dynamic-field/components/date-field/date-time-picker';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import { parseDateTime } from '@internationalized/date';
import {
  type BaseSingleSpaceRentableContract,
  type ModelName,
} from '@pigello/pigello-matrix';
import { useEffect, useState } from 'react';
import useDetailModalContractHelpers from './useDetailModalContractHelpers';

export type ConfirmCancelContractModalContentData = {
  modelName: ModelName;
  id: string;

  data?: BaseSingleSpaceRentableContract;
};

interface Props extends GetModalContentProps {
  closeModal: () => void;
  contentData: ConfirmCancelContractModalContentData;
}

function ConfirmCancelContractModal({
  closeModal,
  contentData: { modelName, id, data },
}: Props) {
  const {
    markAsManualSignedCancellation,

    earliestCancellationSignedDateTime,
  } = useDetailModalContractHelpers(modelName, id, data);

  const [signDateTime, setSignDateTime] = useState<string | null>(
    earliestCancellationSignedDateTime?.toISO({
      includeOffset: false,
    }) ?? null
  );

  useEffect(() => {
    if (earliestCancellationSignedDateTime)
      setSignDateTime(
        earliestCancellationSignedDateTime.toISO({ includeOffset: false })
      );
  }, [earliestCancellationSignedDateTime]);
  return (
    <AlertDialog defaultOpen onOpenChange={closeModal}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Bekräfta uppsägning</AlertDialogTitle>
        </AlertDialogHeader>
        <div className='w-full'>
          <DateTimePicker
            label='Datum för uppsägning'
            granularity='minute'
            hideResetButton={true}
            value={signDateTime !== null ? parseDateTime(signDateTime) : null}
            onChange={(date) => {
              if (!date) return;
              setSignDateTime(date.toString());
            }}
            {...(earliestCancellationSignedDateTime?.toISO() !== null &&
              earliestCancellationSignedDateTime?.toISO() !== undefined && {
                maxValue: parseDateTime(
                  // @ts-expect-error - Luxon types are not correct
                  earliestCancellationSignedDateTime.toISO({
                    includeOffset: false,
                  })
                ),
                minValue: parseDateTime(
                  // @ts-expect-error - Luxon types are not correct
                  earliestCancellationSignedDateTime
                    .minus({ year: 10 })
                    .toISO({ includeOffset: false })
                ),
              })}
          />
        </div>

        <AlertDialogFooter>
          <Button variant='outline' onClick={closeModal}>
            Avbryt
          </Button>
          <Button
            onClick={() => {
              if (!signDateTime) return;

              markAsManualSignedCancellation(signDateTime);

              closeModal();
            }}
            disabled={!signDateTime}
          >
            Bekräfta
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export const getConfirmCancelContractModalContent = (
  props: Props
): ModalContent => {
  return {
    body: <ConfirmCancelContractModal {...props} />,
    isCloseOnEscape: true,
    closeButton: true,
    externalModalHandling: true,
  };
};
