import {
  defaultRelationFilter,
  type IFilter,
} from '@/components/table-filters/constants';
import {
  ApartmentContractConfig,
  IndustrialPremisesConfig,
  OtherContractConfig,
  OutdoorSectionContractConfig,
  ParkingSpotContractConfig,
  TenantConfig,
} from '@pigello/pigello-matrix';
import baseFilters, {
  baseGlobalRealEstatesFilter,
  baseTagFilter,
} from '../../filters';
import baseContractFilters from '../components/base-contract-filters';

const otherContractFilters: IFilter[] = [
  {
    title: OtherContractConfig.fields.tenantCounterpart.verboseName,
    relationModelName: TenantConfig.modelName,
    externalFieldName:
      OtherContractConfig.fields.tenantCounterpart.externalFieldName,
    ...defaultRelationFilter,
  },
  {
    title: OtherContractConfig.fields.subTenantCounterpart.verboseName,
    relationModelName: TenantConfig.modelName,
    externalFieldName:
      OtherContractConfig.fields.subTenantCounterpart.externalFieldName,
    ...defaultRelationFilter,
  },
  {
    title: OtherContractConfig.fields.mainApartmentContract.verboseName,
    relationModelName: ApartmentContractConfig.modelName,
    externalFieldName:
      OtherContractConfig.fields.mainApartmentContract.externalFieldName,
    ...defaultRelationFilter,
  },
  {
    title:
      OtherContractConfig.fields.mainIndustrialPremisesContract.verboseName,
    relationModelName: IndustrialPremisesConfig.modelName,
    externalFieldName:
      OtherContractConfig.fields.mainIndustrialPremisesContract
        .externalFieldName,
    ...defaultRelationFilter,
  },
  {
    title: OtherContractConfig.fields.mainParkingspotContract.verboseName,
    relationModelName: ParkingSpotContractConfig.modelName,
    externalFieldName:
      OtherContractConfig.fields.mainParkingspotContract.externalFieldName,
    ...defaultRelationFilter,
  },
  {
    title: OtherContractConfig.fields.mainOutdoorsectionContract.verboseName,
    relationModelName: OutdoorSectionContractConfig.modelName,
    externalFieldName:
      OtherContractConfig.fields.mainOutdoorsectionContract.externalFieldName,
    ...defaultRelationFilter,
  },
  ...baseGlobalRealEstatesFilter,
  ...baseContractFilters,
  ...baseTagFilter,
  ...baseFilters,
];

export default otherContractFilters;
