import SimplePDFDisplay from '@/components/pdf/simple-pdf-display';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button, buttonVariants } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import OverlayLoader from '@/components/ui/overlay-loader';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Urls } from '@/lib/urls';
import { cn } from '@/lib/utils';
import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import { postApi } from '@/requests/api';
import { BASE_BACKEND_URL } from '@/requests/constants';
import { getCookies } from '@/requests/cookies';
import type { ErrorResponse } from '@/requests/types';
import {
  CloudArrowDownIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid';
import { InvoiceConfig, type ModelName } from '@pigello/pigello-matrix';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'sonner';
import PreviewCoInvoicing from './preview-coinvoicing';
import PreviewDebitRowSummary from './preview-debit-row-summary';
import PreviewDebtSettings from './preview-debt-settings';
import PreviewInvoiceSettings from './preview-invoice-settings';
import PreviewRecipients from './preview-recipients';
import PreviewReminderSettings from './preview-reminder-settings';
import type {
  IInvoiceCreateResult,
  IInvoicePerformResult,
  IInvoicePreviewResult,
  IInvoiceRecipient,
  IInvoiceRow,
} from './types';

export type InvoicePreviewModalResponse = Promise<boolean>;

export type InvoicePreviewContentData = {
  instanceId: string;
  forDate: string;
  attemptUpdateOfUnattested?: boolean;
  modelName: ModelName;
};

function cleanInvoiceFields(
  instance?: Partial<IInvoiceCreateResult>
): Partial<IInvoiceCreateResult> | undefined {
  const cleanInstance = cloneDeep(instance);
  if (!cleanInstance) {
    return;
  }
  cleanInstance.id = undefined;
  cleanInstance.interest_rate = undefined;
  cleanInstance.interest_days_after_due_date = undefined;
  cleanInstance.dimensions = undefined;
  return cleanInstance;
}

function cleanInvoiceRowFields(
  instance?: Partial<IInvoiceRow>[]
): Partial<IInvoiceRow>[] | undefined {
  const cleanedInvoiceRows = [];
  if (!instance) {
    return;
  }
  for (const invoiceRow of instance ?? []) {
    const cleanedInvoiceRow = cloneDeep(invoiceRow);
    cleanedInvoiceRow.id = undefined;
    cleanedInvoiceRows.push(cleanedInvoiceRow);
  }
  return cleanedInvoiceRows;
}

function cleanRecipientFields(
  instance?: Partial<IInvoiceRecipient>[]
): Partial<IInvoiceRecipient>[] | undefined {
  const cleanedRecipients = [];
  if (!instance) {
    return;
  }
  for (const recipient of instance ?? []) {
    const cleanedRecipient = cloneDeep(recipient);
    cleanedRecipient.id = undefined;
    cleanedRecipients.push(cleanedRecipient);
  }
  return cleanedRecipients;
}

type IPDFDraftPreview = {
  data: string;
  recipients: IInvoiceRecipient[];
};

interface Props extends GetModalContentProps {
  closeModal: (response: Awaited<InvoicePreviewModalResponse>) => void;
  contentData: InvoicePreviewContentData;
}

function InvoicePreviewModalContent({ closeModal, contentData }: Props) {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const { instanceId, modelName, forDate, attemptUpdateOfUnattested } =
    contentData;
  const router = useRouter();

  const { data, isError, error, isPending } = useQuery<
    IInvoicePreviewResult,
    ErrorResponse
  >({
    queryFn: async () => {
      const { cookies } = await getCookies();
      const res = await postApi<IInvoicePreviewResult>({
        url: `${BASE_BACKEND_URL}/accounting/contracts/preview/${modelName}/${cookies.organization_id}/`,
        body: JSON.stringify({
          ids: [{ id: instanceId }],
          for_date: forDate,
          attempt_auto_attest: false,
          attempt_update_of_unattested: attemptUpdateOfUnattested,
        }),
      });

      return res;
    },
    queryKey: ['invoicepreview', modelName, forDate, instanceId],
    staleTime: 10 * 1000,
  });

  const { data: pdfDraftPreview } = useQuery<IPDFDraftPreview[], ErrorResponse>(
    {
      queryFn: async () => {
        const res = await postApi<IPDFDraftPreview[]>({
          url: `${BASE_BACKEND_URL}/accounting/pdf/preview_draft/`,
          body: JSON.stringify({
            invoice: cleanInvoiceFields(
              data?.invoice_create[0] ?? data?.invoice_update[0]
            ),
            invoicerow: cleanInvoiceRowFields(data?.invoicerow_create),
            invoicerecipient: cleanRecipientFields(data?.recipient_create),
          }),
        });

        return res;
      },
      queryKey: ['invoicedraftpreview', modelName, forDate, instanceId],
      staleTime: 10 * 1000,
      enabled:
        !!data?.invoice_create ||
        !!data?.invoicerow_create ||
        !!data?.recipient_create,
    }
  );

  useEffect(() => {
    if (isError) {
      toast.error(
        'Något gick fel med förhandsgranskningen. Kontrollera inställningarna och försök igen.',
        {
          description: JSON.stringify(error.errorData) ?? '',
        }
      );
    }
  }, [isError]);

  const { mutate } = useMutation<IInvoicePerformResult, ErrorResponse>({
    mutationFn: async () => {
      setLoading(true);
      const { cookies } = await getCookies();
      const res = await postApi<IInvoicePerformResult>({
        url: `${BASE_BACKEND_URL}/accounting/contracts/perform/${modelName}/${cookies.organization_id}/`,
        body: JSON.stringify({
          ids: [{ id: instanceId }],
          for_date: forDate,
          attempt_auto_attest: false,
          attempt_update_of_unattested: attemptUpdateOfUnattested,
        }),
      });

      return res;
    },
    onSettled: () => {
      setLoading(false);
    },
    onError: () => {
      toast.error('Något gick fel vid genereringen', {
        description: 'Försök igen, kontakta Pigello om problemet kvarstår',
      });
    },
    onSuccess: (data) => {
      closeModal(false);
      queryClient.invalidateQueries({
        queryKey: [InvoiceConfig.modelName],
      });
      toast.success('Avi genererades', {
        action: {
          label: 'Gå till avi',
          onClick: () => {
            const resultId =
              data.invoice_created?.[0] ?? data.invoice_updated?.[0];
            router.push(Urls.detail.invoice.overview(resultId));
          },
        },
      });
    },
  });

  const invoiceData = attemptUpdateOfUnattested
    ? data?.invoice_update?.[0]
    : data?.invoice_create?.[0];

  const coInvoiceData = data?.coinvoicenotation_create;

  const pdfData = useMemo(() => {
    if (!pdfDraftPreview) return null;

    const buffer = atob(pdfDraftPreview[0].data);
    return buffer;
  }, [pdfDraftPreview]);

  const downloadPdf = () => {
    const a = document.createElement('a');
    document.body.appendChild(a);

    a.style.setProperty('display', 'none', 'important');

    const url = pdfDraftPreview?.[0].data
      ? `data:application/pdf;base64, ${pdfDraftPreview[0].data}`
      : undefined;

    if (!url) {
      return toast.error('Misslyckade med att ladda ner förhandsgranskning');
    }
    a.href = url;
    a.download = `${contentData.instanceId}_${DateTime.now().toFormat(
      'yyyy-MM-dd'
    )}.pdf`;
    a.click();
    a.remove();
  };

  const hasData =
    !!data?.invoice_create?.length ||
    !!data?.invoice_update?.length ||
    !!data?.invoice_auto_attest?.length ||
    !!data?.recipient_create?.length ||
    !!data?.invoicerow_create?.length ||
    !!data?.coinvoicenotation_create?.length ||
    !!data?.debtinvoice_create?.length;
  return (
    <>
      <Dialog open onOpenChange={() => closeModal(false)}>
        <DialogContent size='lg' className={'gap-0 p-0 transition-all'}>
          {loading && <OverlayLoader />}
          <Tabs defaultValue='invoice'>
            <DialogHeader>
              <DialogTitle>Förhandsgranskning av avi</DialogTitle>
              <div className='flex items-center gap-2'>
                {hasData && (
                  <TabsList>
                    <TabsTrigger value={'invoice'}>Avi</TabsTrigger>
                    <TabsTrigger value='debitrows'>
                      Debiteringsrader
                    </TabsTrigger>
                    <TabsTrigger value='pdfDraftPreview' disabled={!pdfData}>
                      Förhandsgranskning
                    </TabsTrigger>
                  </TabsList>
                )}
                <Button
                  variant='secondary'
                  size='icon-sm'
                  onClick={() => closeModal(false)}
                >
                  <XMarkIcon className='size-4' />
                </Button>
              </div>
              <DialogDescription className='sr-only'>
                Visa förhandsgranskning av avi
              </DialogDescription>
            </DialogHeader>

            {isPending && <OverlayLoader />}

            {hasData && !isPending && (
              <div className='h-[500px] gap-4 overflow-y-auto px-4 pb-4'>
                <TabsContent className='grid gap-4' value='invoice'>
                  {data?.recipient_create && (
                    <PreviewRecipients recipients={data.recipient_create} />
                  )}

                  {coInvoiceData && coInvoiceData?.length > 0 && (
                    <PreviewCoInvoicing coInvoiceData={coInvoiceData} />
                  )}

                  {invoiceData && (
                    <PreviewInvoiceSettings invoiceData={invoiceData} />
                  )}

                  {invoiceData && (
                    <div className='grid grid-cols-2 items-start gap-4'>
                      <PreviewReminderSettings invoiceData={invoiceData} />
                      <PreviewDebtSettings invoiceData={invoiceData} />
                    </div>
                  )}
                </TabsContent>

                <TabsContent
                  className='grid items-start gap-4'
                  value='debitrows'
                >
                  {invoiceData && (
                    <PreviewDebitRowSummary
                      roundDecimals={invoiceData.use_rounding}
                      debitRows={data?.invoicerow_create}
                      adminFee={invoiceData.admin_fee}
                      vatMethod={invoiceData.admin_fee_vat_method as 0 | 1 | 2}
                    />
                  )}
                </TabsContent>
                <TabsContent
                  className='grid items-start gap-4'
                  value='pdfDraftPreview'
                >
                  <SimplePDFDisplay
                    file={
                      pdfData
                        ? {
                            data: pdfData,
                          }
                        : undefined
                    }
                  />
                  <Button onClick={downloadPdf} variant='secondary'>
                    Ladda ner förhandsgranskning av PDF{' '}
                    <CloudArrowDownIcon className='ml-1 size-4' />
                  </Button>
                </TabsContent>
              </div>
            )}
            {!hasData && !isPending && (
              <div className='flex flex-col items-center justify-center gap-2 p-4'>
                <Alert>
                  <InformationCircleIcon className='size-4' />
                  <AlertTitle>
                    Det finns ingen data att visa för detta avtal. Detta kan
                    bero på en eller flera av följande
                  </AlertTitle>
                  <AlertDescription>
                    <ul className='mt-2 list-disc space-y-1'>
                      <li>
                        Avin är redan genererad för detta avtal och inga
                        ändringar har gjorts på avtalet.
                      </li>
                      <li>Avin är redan genererad och attesterad.</li>
                      <li>
                        Avtalet saknar{' '}
                        <Link
                          prefetch={false}
                          className={cn(
                            buttonVariants({ variant: 'link' }),
                            'p-0'
                          )}
                          href={
                            Urls.settings.modules.invoicing.invoicingsettings
                              .contractinvoicing
                          }
                        >
                          aviseringsinställning.
                        </Link>
                      </li>
                      <li>
                        Avtalet saknar{' '}
                        <Link
                          prefetch={false}
                          className={cn(
                            buttonVariants({ variant: 'link' }),
                            'p-0'
                          )}
                          href={
                            Urls.settings.modules.invoicing.invoicingsettings
                              .paymentterms
                          }
                        >
                          betalningsvillkorsinställning.
                        </Link>
                      </li>
                      <li>Avtalet aviseras inte för vald period.</li>
                      <li>Det finns inga debiteringsrader att avisera för.</li>
                      <li>
                        Det finns inget aviserande bolag kopplat till
                        fastigheten som avtalet avser.
                      </li>
                      <li>
                        Det ekonomiska tillträdet på fastigheten är efter
                        perioden som är vald.
                      </li>
                      <li>
                        Det ekonomiska frånträdet på fastigheten är innan
                        perioden som är vald.
                      </li>
                    </ul>
                  </AlertDescription>
                </Alert>
              </div>
            )}
            <DialogFooter>
              <Button variant='outline' onClick={() => closeModal(false)}>
                Stäng
              </Button>
              {hasData && (
                <Button onClick={() => mutate()}>
                  {attemptUpdateOfUnattested ? 'Uppdatera avi' : 'Generera avi'}
                </Button>
              )}
            </DialogFooter>
          </Tabs>
        </DialogContent>
      </Dialog>
    </>
  );
}

export const getInvoicePreviewModalContent = ({
  closeModal,
  contentData,
}: Props): ModalContent => {
  return {
    externalModalHandling: true,
    body: (
      <InvoicePreviewModalContent
        closeModal={closeModal}
        contentData={contentData}
      />
    ),
  };
};
