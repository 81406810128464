import {
  defaultBooleanFilter,
  defaultDateFilter,
  defaultEnumFilter,
  defaultNullFilter,
  defaultRelationFilter,
  defaultStringFilter,
  type IFilter,
} from '@/components/table-filters/constants';

import { RealEstateConfig, TenantConfig } from '@pigello/pigello-matrix';
import baseFilters, { baseTagFilter } from '../filters';

const TENANT_STATUSES: IFilter['enumChoices'] = [
  {
    filters: [{ operator: '__in', value: '0,1,2,3,4,5,7,8' }],
    title: 'Aktiv',
    badgeVariant: 'green',
  },
  {
    filters: [{ operator: '__in', value: '9,10' }],
    title: 'Kommande',
    badgeVariant: 'blue',
  },
  {
    filters: [{ operator: '__in', value: '6,11,12' }],
    title: 'Tidigare',
    badgeVariant: 'gray',
  },
];

const tenantFilters: IFilter[] = [
  {
    title: TenantConfig.fields.status.verboseName,
    externalFieldName: TenantConfig.fields.status.externalFieldName,
    enumChoices: TENANT_STATUSES,
    ...defaultEnumFilter,
  },
  {
    title: TenantConfig.fields.customId.verboseName,
    externalFieldName: TenantConfig.fields.customId.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: TenantConfig.fields.isCompany.verboseName,
    externalFieldName: TenantConfig.fields.corporateName.externalFieldName,
    ...defaultNullFilter,
  },
  {
    title: TenantConfig.fields.communicationName.verboseName,
    externalFieldName: TenantConfig.fields.communicationName.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: TenantConfig.fields.corporateName.verboseName,
    externalFieldName: TenantConfig.fields.corporateName.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: TenantConfig.fields.ssn.verboseName,
    externalFieldName: TenantConfig.fields.ssn.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: TenantConfig.fields.orgNo.verboseName,
    externalFieldName: TenantConfig.fields.orgNo.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: TenantConfig.fields.email.verboseName,
    externalFieldName: TenantConfig.fields.email.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: TenantConfig.fields.invoiceEmail.verboseName,
    externalFieldName: TenantConfig.fields.invoiceEmail.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: TenantConfig.fields.phoneNumber.verboseName,
    externalFieldName: TenantConfig.fields.phoneNumber.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: TenantConfig.fields.anonymizationName.verboseName,
    externalFieldName: TenantConfig.fields.anonymizationName.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: TenantConfig.fields.realestates.verboseName,
    externalFieldName: TenantConfig.fields.realestates.externalFieldName,
    relationModelName: RealEstateConfig.modelName,
    ...defaultRelationFilter,
  },
  {
    title: TenantConfig.fields.username.verboseName,
    externalFieldName: TenantConfig.fields.username.externalFieldName,

    ...defaultStringFilter,
  },
  {
    title: TenantConfig.fields.sniNumber.verboseName,
    externalFieldName: TenantConfig.fields.sniNumber.externalFieldName,

    ...defaultStringFilter,
  },
  {
    title: TenantConfig.fields.ssnIsCoordinationNumber.verboseName,
    externalFieldName:
      TenantConfig.fields.ssnIsCoordinationNumber.externalFieldName,

    ...defaultBooleanFilter,
  },
  {
    title: TenantConfig.fields.isActive.verboseName,
    externalFieldName: TenantConfig.fields.isActive.externalFieldName,
    ...defaultBooleanFilter,
  },
  {
    title: TenantConfig.fields.birthDate.verboseName,
    externalFieldName: TenantConfig.fields.birthDate.externalFieldName,
    ...defaultDateFilter,
  },

  {
    title: TenantConfig.fields.latestActiveDate.verboseName,
    externalFieldName: TenantConfig.fields.latestActiveDate.externalFieldName,
    ...defaultDateFilter,
  },
  {
    title: TenantConfig.fields.earliestActiveDate.verboseName,
    externalFieldName: TenantConfig.fields.earliestActiveDate.externalFieldName,

    ...defaultDateFilter,
  },
  {
    title: TenantConfig.fields.lastLogin.verboseName,
    externalFieldName: TenantConfig.fields.lastLogin.externalFieldName,
    ...defaultDateFilter,
  },
  {
    title: TenantConfig.fields.emailVerified.verboseName,
    externalFieldName: TenantConfig.fields.emailVerified.externalFieldName,
    ...defaultBooleanFilter,
  },
  {
    title: TenantConfig.fields.phoneNumberVerified.verboseName,
    externalFieldName:
      TenantConfig.fields.phoneNumberVerified.externalFieldName,
    ...defaultBooleanFilter,
  },

  {
    title: TenantConfig.fields.activeTo.verboseName,
    externalFieldName: TenantConfig.fields.activeTo.externalFieldName,

    ...defaultDateFilter,
  },

  {
    title: `${TenantConfig.fields.invoiceAddress.verboseName} - Gatunamn`,
    externalFieldName: `${TenantConfig.fields.invoiceAddress.externalFieldName}__street`,
    ...defaultStringFilter,
  },
  {
    title: `${TenantConfig.fields.invoiceAddress.verboseName} - Stad`,
    externalFieldName: `${TenantConfig.fields.invoiceAddress.externalFieldName}__city`,
    ...defaultStringFilter,
  },
  {
    title: `${TenantConfig.fields.invoiceAddress.verboseName} - Gatunummer`,
    externalFieldName: `${TenantConfig.fields.invoiceAddress.externalFieldName}__street_number`,
    ...defaultStringFilter,
  },
  {
    title: `${TenantConfig.fields.invoiceAddress.verboseName} - Land`,
    externalFieldName: `${TenantConfig.fields.invoiceAddress.externalFieldName}__country`,
    ...defaultStringFilter,
  },

  {
    title: `${TenantConfig.fields.currentRegisteredAddress.verboseName} - Gatunamn`,
    externalFieldName: `${TenantConfig.fields.currentRegisteredAddress.externalFieldName}__street`,
    ...defaultStringFilter,
  },
  {
    title: `${TenantConfig.fields.currentRegisteredAddress.verboseName} - Stad`,
    externalFieldName: `${TenantConfig.fields.currentRegisteredAddress.externalFieldName}__city`,
    ...defaultStringFilter,
  },
  {
    title: `${TenantConfig.fields.currentRegisteredAddress.verboseName} - Gatunummer`,
    externalFieldName: `${TenantConfig.fields.currentRegisteredAddress.externalFieldName}__street_number`,
    ...defaultStringFilter,
  },
  {
    title: `${TenantConfig.fields.currentRegisteredAddress.verboseName} - Land`,
    externalFieldName: `${TenantConfig.fields.currentRegisteredAddress.externalFieldName}__country`,
    ...defaultStringFilter,
  },

  {
    title: `${TenantConfig.fields.futureAddress.verboseName} - Gatunamn`,
    externalFieldName: `${TenantConfig.fields.futureAddress.externalFieldName}__street`,
    ...defaultStringFilter,
  },
  {
    title: `${TenantConfig.fields.futureAddress.verboseName} - Stad`,
    externalFieldName: `${TenantConfig.fields.futureAddress.externalFieldName}__city`,
    ...defaultStringFilter,
  },
  {
    title: `${TenantConfig.fields.futureAddress.verboseName} - Gatunummer`,
    externalFieldName: `${TenantConfig.fields.futureAddress.externalFieldName}__street_number`,
    ...defaultStringFilter,
  },
  {
    title: `${TenantConfig.fields.futureAddress.verboseName} - Land`,
    externalFieldName: `${TenantConfig.fields.futureAddress.externalFieldName}__country`,
    ...defaultStringFilter,
  },

  {
    title: `${TenantConfig.fields.previousAddress.verboseName} - Gatunamn`,
    externalFieldName: `${TenantConfig.fields.previousAddress.externalFieldName}__street`,
    ...defaultStringFilter,
  },
  {
    title: `${TenantConfig.fields.previousAddress.verboseName} - Stad`,
    externalFieldName: `${TenantConfig.fields.previousAddress.externalFieldName}__city`,
    ...defaultStringFilter,
  },
  {
    title: `${TenantConfig.fields.previousAddress.verboseName} - Gatunummer`,
    externalFieldName: `${TenantConfig.fields.previousAddress.externalFieldName}__street_number`,
    ...defaultStringFilter,
  },
  {
    title: `${TenantConfig.fields.previousAddress.verboseName} - Land`,
    externalFieldName: `${TenantConfig.fields.previousAddress.externalFieldName}__country`,
    ...defaultStringFilter,
  },

  ...baseTagFilter,
  ...baseFilters,
];

export default tenantFilters;
