import type {
  IEnumChoice,
  IFilter,
} from '@/components/table-filters/constants';
import {
  defaultBooleanFilter,
  defaultDateFilter,
  defaultEnumFilter,
  defaultRelationFilter,
  defaultStringFilter,
  NUMBER_FILTER,
  OPERATORS,
} from '@/components/table-filters/constants';
import {
  CompanyConfig,
  InvoiceConfig,
  TenantConfig,
} from '@pigello/pigello-matrix';
import baseFilters, {
  baseGlobalRealEstatesFilter,
  baseTagFilter,
} from '../../filters';

const INVOICE_STATUS_FILTERS: IEnumChoice[] = [
  {
    filters: [{ operator: 'noop', value: 'credit_rental_invoices' }],
    title: 'Kreditavier',
    badgeVariant: 'destructive',
    overrideExternalFieldName: 'custom_invoice_filter',
  },
  {
    filters: [{ operator: 'noop', value: 'credit_invoices' }],
    title: 'Kreditfakturor',
    badgeVariant: 'destructive',
    overrideExternalFieldName: 'custom_invoice_filter',
  },
  {
    filters: [{ operator: 'noop', value: 'not_attested' }],
    title: 'Ej attesterade',
    badgeVariant: 'yellow',
    overrideExternalFieldName: 'custom_invoice_filter',
  },
  {
    filters: [
      {
        operator: 'noop',
        value: 'sent',
      },
    ],
    title: 'Skickade',
    badgeVariant: 'blue',
    overrideExternalFieldName: 'custom_invoice_filter',
  },
  {
    filters: [
      {
        operator: 'noop',
        value: 'not_sent',
      },
    ],
    title: 'Ej skickade',
    badgeVariant: 'cyan',
    overrideExternalFieldName: 'custom_invoice_filter',
  },
  {
    filters: [
      {
        operator: 'noop',
        value: 'fully_paid',
      },
    ],
    title: 'Slutbetalda',
    badgeVariant: 'green',
    overrideExternalFieldName: 'custom_invoice_filter',
  },

  {
    filters: [
      {
        operator: 'noop',
        value: 'overdue',
      },
    ],
    title: 'Förfallna',
    badgeVariant: 'destructive',
    overrideExternalFieldName: 'custom_invoice_filter',
  },
  {
    filters: [
      {
        operator: 'noop',
        value: 'reminders',
      },
    ],
    title: 'Påminnelser',
    badgeVariant: 'purple',
    overrideExternalFieldName: 'custom_invoice_filter',
  },

  {
    filters: [
      {
        operator: 'noop',
        value: 'overdue_not_handled',
      },
    ],
    title: 'Förfallna, manuell hantering',
    badgeVariant: 'destructive',
    overrideExternalFieldName: 'custom_invoice_filter',
  },
];

const invoiceFilters: IFilter[] = [
  {
    title: 'Status',
    externalFieldName: 'custom_invoice_filter',
    enumChoices: INVOICE_STATUS_FILTERS,
    ...defaultEnumFilter,
  },
  {
    title: InvoiceConfig.fields.rentalInvoice.verboseName,
    externalFieldName: InvoiceConfig.fields.rentalInvoice.externalFieldName,
    ...defaultBooleanFilter,
  },
  {
    title: InvoiceConfig.fields.invoiceNumber.verboseName,
    externalFieldName: InvoiceConfig.fields.invoiceNumber.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: InvoiceConfig.fields.ocrNumber.verboseName,
    externalFieldName: InvoiceConfig.fields.ocrNumber.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: InvoiceConfig.fields.attestedDate.verboseName,
    externalFieldName: InvoiceConfig.fields.attestedDate.externalFieldName,
    ...defaultDateFilter,
  },
  {
    title: InvoiceConfig.fields.closedTime.verboseName,
    externalFieldName: InvoiceConfig.fields.closedTime.externalFieldName,
    ...defaultDateFilter,
  },
  {
    title: InvoiceConfig.fields.invoiceDate.verboseName,
    externalFieldName: InvoiceConfig.fields.invoiceDate.externalFieldName,
    ...defaultDateFilter,
  },
  {
    title: InvoiceConfig.fields.dueDate.verboseName,
    externalFieldName: InvoiceConfig.fields.dueDate.externalFieldName,
    ...defaultDateFilter,
  },
  {
    title: InvoiceConfig.fields.mainPeriodStart.verboseName,
    externalFieldName: InvoiceConfig.fields.mainPeriodStart.externalFieldName,
    ...defaultDateFilter,
  },
  {
    title: InvoiceConfig.fields.mainPeriodEnd.verboseName,
    externalFieldName: InvoiceConfig.fields.mainPeriodEnd.externalFieldName,
    ...defaultDateFilter,
  },
  {
    title: InvoiceConfig.fields.company.verboseName,
    externalFieldName: InvoiceConfig.fields.company.externalFieldName,
    relationModelName: CompanyConfig.modelName,
    ...defaultRelationFilter,
  },
  {
    title: InvoiceConfig.fields.useRounding.verboseName,
    externalFieldName: InvoiceConfig.fields.useRounding.externalFieldName,
    ...defaultBooleanFilter,
  },
  {
    title: InvoiceConfig.fields.utilizeCustomerBalance.verboseName,
    externalFieldName:
      InvoiceConfig.fields.utilizeCustomerBalance.externalFieldName,
    ...defaultBooleanFilter,
  },
  {
    title: 'Originalbelopp brutto (SEK)',
    externalFieldName: 'total_value',
    kind: NUMBER_FILTER,
    operators: [...OPERATORS.LTE, ...OPERATORS.GTE],
    defaultOperator: OPERATORS.GTE[0],
  },
  {
    title: 'Hyresgäst',
    relationModelName: TenantConfig.modelName,
    externalFieldName: 'tenant',
    ...defaultRelationFilter,
    operators: ['noop'],
    defaultOperator: 'noop',
  },
  ...baseGlobalRealEstatesFilter,
  ...baseTagFilter,
  ...baseFilters,
];

export default invoiceFilters;
