'use client';

import { Chapters } from '@/app/detail/[type]/[id]/[subpage]/chapter-data';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useModal } from '@/hooks/useModal';
import { usePerms } from '@/hooks/usePerms';
import { raise } from '@/lib/utils';
import {
  ChartBarIcon,
  EllipsisHorizontalIcon,
  NoSymbolIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid';
import type {
  ApartmentContract,
  BaseContractRevenueRow,
  BaseSingleSpaceRentableContract,
  BaseSpaceRevenueRow,
  FieldValue,
  IndustrialPremisesContract,
  ModelName,
  OutdoorSectionContract,
  ParkingSpotContract,
} from '@pigello/pigello-matrix';
import {
  IndexationSettingUsageConfig,
  type BaseInstance,
  type IBaseInstanceConfig,
} from '@pigello/pigello-matrix';
import { useQueryClient } from '@tanstack/react-query';
import type { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import DeleteModal from '../delete-modal/delete-modal';
import useRevenueRowHelpers from '../detail/revenue/useRevenueRowHelpers';
import useDetailModalContractHelpers from '../detail/shared/contract/useDetailModalContractHelpers';

export function TableActions<Instance extends BaseInstance>({
  instance,
  title,
  config,
  disableEdit,
  disableDelete,
  overrideEdit,
  overrideModelName,
}: {
  instance: Instance;
  title: FieldValue<string>;
  config: IBaseInstanceConfig<Instance>;
  overrideModelName?: ModelName | string;
  disableEdit?: boolean;
  disableDelete?: boolean;
  overrideEdit?: (router: AppRouterInstance) => void;
}) {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteIndexationId, setDeleteIndexationId] = useState<string | null>(
    null
  );
  const { openUpdateSingleInstanceModal } = useModal();
  const { canDelete, canUpdate } = usePerms(config.modelName);
  const queryclient = useQueryClient();
  const {
    isContract,
    canCancel,
    openCancelContractModal,
    latestCancellationTime,
  } = useDetailModalContractHelpers(
    config.modelName,
    instance.id as string,
    instance as unknown as BaseSingleSpaceRentableContract
  );
  const {
    isRevenueRow,
    isIndexable,
    openIndexationUsageModal,
    canEdit: canEditRevenueRow,
    hasIndexUsage,
    indexUsageId,
  } = useRevenueRowHelpers(
    config.modelName,
    instance?.id as string,
    instance as unknown as BaseContractRevenueRow | BaseSpaceRevenueRow
  );

  const router = useRouter();
  return (
    <>
      {deleteOpen && (
        <DeleteModal
          id={instance.id}
          modelName={config.modelName}
          setOpen={() => setDeleteOpen(false)}
        />
      )}

      {deleteIndexationId && (
        <DeleteModal
          id={deleteIndexationId}
          information='Bekräfta att du vill ta bort indexuppräkning'
          modelName={IndexationSettingUsageConfig.modelName}
          setOpen={() => setDeleteIndexationId(null)}
          onSuccess={() => {
            queryclient.invalidateQueries({
              queryKey: [config.modelName],
            });
          }}
        />
      )}

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button data-action='true' variant={'ghost'} size={'icon-sm'}>
            <EllipsisHorizontalIcon className='size-4' />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align='end'>
          {canUpdate && (
            <>
              {(canEditRevenueRow || !isRevenueRow) && !disableEdit && (
                <DropdownMenuItem
                  onClick={
                    overrideEdit
                      ? () => overrideEdit(router)
                      : () =>
                          openUpdateSingleInstanceModal({
                            title: `Redigera ${title}`,
                            config,
                            id:
                              instance.id ?? raise('No id provided in actions'),
                            chapters:
                              // @ts-expect-error TODO: fix this
                              Chapters?.[overrideModelName] ??
                              // @ts-expect-error TODO: fix this
                              Chapters?.[config.modelName],
                          })
                  }
                >
                  <PencilIcon className='mr-2 size-4' />
                  Redigera
                </DropdownMenuItem>
              )}

              {isContract && canCancel && (
                <DropdownMenuItem
                  onClick={() =>
                    openCancelContractModal({
                      instanceId: instance.id as string,
                      modelName: config.modelName,

                      contract: instance as unknown as
                        | ApartmentContract
                        | IndustrialPremisesContract
                        | OutdoorSectionContract
                        | ParkingSpotContract,
                      latestCancellationTime,
                    })
                  }
                >
                  <XMarkIcon className='mr-2 size-4' />
                  Säg upp avtal
                </DropdownMenuItem>
              )}
              {isRevenueRow && (
                <>
                  {isIndexable && (
                    <DropdownMenuItem
                      onClick={() =>
                        openIndexationUsageModal({
                          baseRowModelName: config.modelName,
                          baseRowId: instance.id as string,
                        })
                      }
                    >
                      <ChartBarIcon className='mr-2 size-4' />
                      Lägg till indexuppräkning
                    </DropdownMenuItem>
                  )}

                  {hasIndexUsage && !!indexUsageId && (
                    <DropdownMenuItem
                      onClick={() =>
                        openIndexationUsageModal({
                          instanceId: indexUsageId,
                        })
                      }
                    >
                      <ChartBarIcon className='mr-2 size-4' />
                      Redigera indexuppräkning
                    </DropdownMenuItem>
                  )}

                  {hasIndexUsage && !!indexUsageId && (
                    <DropdownMenuItem
                      onClick={() => setDeleteIndexationId(indexUsageId)}
                    >
                      <NoSymbolIcon className='mr-2 size-4' />
                      Ta bort indexuppräkning
                    </DropdownMenuItem>
                  )}
                </>
              )}
            </>
          )}
          {canDelete && (
            <>
              {!disableDelete && (
                <>
                  <DropdownMenuItem onClick={() => setDeleteOpen(true)}>
                    <TrashIcon className='mr-2 h-4' /> Radera
                  </DropdownMenuItem>
                </>
              )}
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
