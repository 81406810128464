import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header';
import { Checkbox } from '@/components/ui/checkbox';
import { toMoneyString } from '@/lib/utils';
import type { InvoiceRow } from '@pigello/pigello-matrix';
import {
  ApartmentContractRevenueRowConfig,
  InvoiceRowConfig,
} from '@pigello/pigello-matrix';
import type { ColumnDef } from '@tanstack/react-table';

import ArticleRowInfo from '@/components/article/article-row-info';
import { TableActions } from '@/components/data-table/table-actions';
import { Badge } from '@/components/ui/badge';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { toReadableDate } from '@/lib/dates';
import { InformationCircleIcon } from '@heroicons/react/16/solid';
//

export const invoiceRowDebitColumns: ColumnDef<InvoiceRow>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label='Select all'
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label='Select row'
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },

  {
    accessorKey: 'title',
    enableSorting: true,
    meta: {
      columnVisibilityName:
        ApartmentContractRevenueRowConfig.fields.title.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      return <div className='whitespace-nowrap'>{row.original.title}</div>;
    },
  },

  {
    accessorKey: 'article',
    enableSorting: false,
    meta: {
      columnVisibilityName: InvoiceRowConfig.fields.article.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      return (
        <Tooltip disableHoverableContent={!row.original.article?.name}>
          <TooltipTrigger>
            <Badge variant='outline'>
              {row.original.article?.name ?? 'Artikel saknas'}
              <InformationCircleIcon className='ml-1 size-4' />
            </Badge>
          </TooltipTrigger>
          <TooltipContent>
            <ArticleRowInfo id={row.original.article?.id} />
          </TooltipContent>
        </Tooltip>
      );
    },
  },

  {
    accessorKey: 'vat',
    enableSorting: true,
    meta: {
      columnVisibilityName: InvoiceRowConfig.fields.vat.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      const totalAmount =
        row.original.unitAmount * (row.original?.unitPrice ?? 0);

      return (
        <div className='whitespace-nowrap'>{toMoneyString(totalAmount)}</div>
      );
    },
  },
  {
    accessorKey: 'unit',
    enableSorting: true,
    meta: {
      columnVisibilityName: InvoiceRowConfig.fields.unit.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      return (
        <div className='whitespace-nowrap'>{row.original.unit ?? '-'}</div>
      );
    },
  },
  {
    accessorKey: 'debit',
    enableSorting: true,
    meta: {
      columnVisibilityName: 'Debiteras',
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      const totalAmount =
        row.original.unitAmount * (row.original?.unitPrice ?? 0);

      return <div className='text-end'>{toMoneyString(totalAmount)}</div>;
    },
  },

  {
    accessorKey: 'unitPrice',
    enableSorting: true,
    meta: {
      columnVisibilityName: InvoiceRowConfig.fields.unitPrice.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      return (
        <div className='text-end'>
          {toMoneyString(row.original?.unitPrice ?? 0)}
        </div>
      );
    },
  },
  {
    accessorKey: 'unitAmount',
    enableSorting: true,
    meta: {
      columnVisibilityName: InvoiceRowConfig.fields.unitAmount.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      return <div className='text-end'>{row.original.unitAmount}</div>;
    },
  },
  {
    accessorKey: 'usedArticleAccount',
    enableSorting: true,
    meta: {
      columnVisibilityName:
        InvoiceRowConfig.fields.usedArticleAccount.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      return <div className='text-end'>{row.original.usedArticleAccount}</div>;
    },
  },
  {
    accessorKey: 'rotKind',
    enableSorting: true,
    meta: {
      columnVisibilityName: InvoiceRowConfig.fields.rotKind.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      return (
        <div className='whitespace-nowrap'>
          {InvoiceRowConfig.fields.rotKind.choices?.find(
            (choice) => choice.v === row.original.rotKind
          )?.d ?? '-'}
        </div>
      );
    },
  },
  {
    accessorKey: 'rotRutWorkHours',
    enableSorting: true,
    meta: {
      columnVisibilityName: InvoiceRowConfig.fields.rotRutWorkHours.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      return (
        <div className='text-end'>{row.original.rotRutWorkHours ?? '-'}</div>
      );
    },
  },
  {
    accessorKey: 'rotRutWorkValue',
    enableSorting: true,
    meta: {
      columnVisibilityName: InvoiceRowConfig.fields.rotRutWorkValue.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      return (
        <div className='text-end'>{row.original.rotRutWorkValue ?? '-'}</div>
      );
    },
  },
  {
    accessorKey: 'rotRutMaterialValue',
    enableSorting: true,
    meta: {
      columnVisibilityName:
        InvoiceRowConfig.fields.rotRutMaterialValue.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      return (
        <div className='text-end'>
          {row.original.rotRutMaterialValue ?? '-'}
        </div>
      );
    },
  },
  {
    accessorKey: 'periodStart',
    meta: {
      columnVisibilityName: InvoiceRowConfig.fields.periodStart.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {toReadableDate(row.original.createdAt)}
      </div>
    ),
  },
  {
    accessorKey: 'periodEnd',
    meta: {
      columnVisibilityName: InvoiceRowConfig.fields.periodEnd.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {toReadableDate(row.original.periodEnd)}
      </div>
    ),
  },
  {
    accessorKey: 'createdAt',
    meta: {
      columnVisibilityName: InvoiceRowConfig.fields.createdAt.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {toReadableDate(row.original.createdAt)}
      </div>
    ),
  },
  {
    id: 'actions',
    enableSorting: false,
    enableHiding: false,
    cell: ({ row }) => (
      <TableActions<InvoiceRow>
        instance={row.original}
        config={InvoiceRowConfig}
        title={row.original.title}
      />
    ),
  },
];
