import { DynamicField } from '@/components/form/dynamic-field';
import { Icons } from '@/components/icons';
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Form } from '@/components/ui/form';
import { Skeleton } from '@/components/ui/skeleton';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useGetFileList } from '@/config/documents/filehandling/file/client';
import { useMutateFile } from '@/config/documents/filehandling/file/mutate';
import { useGetFolderList } from '@/config/documents/filehandling/folder/client';
import { useCreateFolder } from '@/config/documents/filehandling/folder/create';
import { useForm } from '@/hooks/useForm';
import { handleFormErrors } from '@/hooks/useForm/utils';
import { useToast } from '@/hooks/useToast';
import { cn } from '@/lib/utils';
import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import type { ErrorResponse } from '@/requests/types';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  FolderIcon,
  FolderPlusIcon,
  InboxIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid';
import {
  FolderConfig,
  getCleanFolder,
  type File,
  type Folder,
  type RelationalFieldValue,
} from '@pigello/pigello-matrix';
import { useState } from 'react';
import { GetIcon } from './get-icon';

export type MoveFileModalContentData = {
  title: string;
  file: File;
};

interface Props extends GetModalContentProps {
  closeModal: () => void;
  contentData: MoveFileModalContentData;
}

function MoveFileModal({ closeModal, contentData }: Props) {
  const { mutateAsync } = useMutateFile({});
  const { data: currentLocation } = useGetFolderList({
    overrideUrl: contentData.file.notationContentType.id,
    queryParams: {
      filters: {
        notation_content_type: {
          noop: contentData.file.notationContentType.id,
        },
        id: { noop: contentData.file.id },
        ...(contentData.file.accessShare?.id
          ? {
              access_share: {
                __in: contentData.file.accessShare?.id,
              },
            }
          : {
              access_share: {
                __isnull: true,
              },
            }),
      },
    },
    nested: ['folder'],
  });
  const [breadcrumbs, setBreadcrumbs] = useState<Array<Folder>>([]);
  const [currentFolder, setCurrentFolder] = useState<Folder>();
  const [highlightedFolder, setHighlightedFolder] = useState<string>();
  const { data: folders, isPending } = useGetFolderList({
    overrideUrl: contentData.file.notationContentType.id,
    queryParams: {
      page: 1,
      pageSize: 100,
      filters: {
        notation_content_type: {
          noop: contentData.file.notationContentType.id,
        },
        ...(contentData.file.accessShare?.id
          ? {
              access_share: {
                __in: contentData.file.accessShare?.id,
              },
            }
          : {
              access_share: {
                __isnull: true,
              },
            }),
        id: {
          '__in!': contentData.file.id,
        },
        ...(!currentFolder &&
          breadcrumbs.length === 0 && { folder: { __isnull: true } }),
        ...(currentFolder &&
          breadcrumbs.length > 0 && { folder: { __in: currentFolder?.id } }),
      },
    },
    nested: ['folder'],
  });
  const { data: files, isLoading: isLoadingFiles } = useGetFileList({
    overrideUrl: contentData.file.notationContentType.id,
    queryParams: {
      page: 1,
      pageSize: 100,
      filters: {
        notation_content_type: {
          noop: contentData.file.notationContentType.id,
        },
        ...(currentFolder?.id && {
          folder: {
            noop: currentFolder?.id,
          },
        }),
      },
    },
    enabled: !!currentFolder,
  });
  const { addToast } = useToast();
  const onSubmit = () => {
    if (!highlightedFolder) {
      return addToast({
        type: 'error',
        title: 'Du måste välja en mapp',
      });
    }
    addToast({
      type: 'promise',
      promise: mutateAsync({
        id: contentData.file.id,
        body: {
          folder: { id: highlightedFolder },
        },
      }),
      loading: 'Sparar...',
      success: () => {
        closeModal();
        return 'Sparat!';
      },
      error: 'Något gick fel',
    });
  };
  const handleClickBreadcrumb = (crumb: Folder) => {
    const index = breadcrumbs.indexOf(crumb);
    const newCrumbs = breadcrumbs.slice(0, index + 1);
    setBreadcrumbs(newCrumbs);
    setCurrentFolder(newCrumbs.at(-1));
  };
  return (
    <>
      <div className={'border-b px-4'}>
        <div className='pb-2 text-sm'>
          Aktuell plats: {currentLocation?.list?.[0]?.name ?? 'Alla filer'}
        </div>
        <div className='mb-2 flex h-6 items-center gap-2'>
          {breadcrumbs.length > 0 && (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  onClick={() => {
                    if (breadcrumbs.length === 1) {
                      setCurrentFolder(undefined);
                      setBreadcrumbs([]);
                      setHighlightedFolder(undefined);
                    } else {
                      const newCrumbs = breadcrumbs.slice(0, -1);
                      setBreadcrumbs(newCrumbs);
                      setCurrentFolder(newCrumbs.at(-1));
                    }
                  }}
                  variant={'ghost'}
                  size={'icon'}
                  className='p-1'
                >
                  <ArrowLeftIcon className='size-4' />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Gå tillbaka</TooltipContent>
            </Tooltip>
          )}
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem
                className={cn(
                  breadcrumbs.length > 0 &&
                    'cursor-pointer hover:text-foreground',
                  !breadcrumbs.length && 'text-foreground'
                )}
                onClick={() => {
                  setCurrentFolder(undefined);
                  setHighlightedFolder(undefined);
                  setBreadcrumbs([]);
                }}
              >
                {' '}
                Hem
              </BreadcrumbItem>
              {breadcrumbs.length > 0 && <BreadcrumbSeparator />}
              <BreadcrumbItem>
                {breadcrumbs.length > 1 && (
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <BreadcrumbEllipsis />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align='start'>
                      {breadcrumbs.toSpliced(-1).map((crumb) => (
                        <DropdownMenuItem
                          key={crumb.id}
                          onClick={() => handleClickBreadcrumb(crumb)}
                        >
                          {crumb.name}
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}
              </BreadcrumbItem>
              {breadcrumbs.length > 1 && <BreadcrumbSeparator />}
              {currentFolder && (
                <BreadcrumbPage className='max-w-72 truncate'>
                  {currentFolder.name}
                </BreadcrumbPage>
              )}
            </BreadcrumbList>
          </Breadcrumb>
        </div>
      </div>
      <div
        className={cn(
          'max-h-[300px] min-h-[min(300px,calc(var(--window-keeper-height)-175px))] space-y-1 overflow-y-auto py-4',
          isPending && 'space-y-2.5'
        )}
      >
        {!isPending &&
          !isLoadingFiles &&
          folders?.list.length === 0 &&
          files?.list.length === 0 && (
            <div className='flex h-[236px] max-h-[400px] flex-col items-center justify-center gap-2'>
              <InboxIcon className='size-10' />
              <span className='text-sm font-medium'>Denna mapp är tom.</span>
            </div>
          )}
        {isPending &&
          Array.from({ length: 5 }).map((_, i) => {
            const length = ['w-52', 'w-60', 'w-72', 'w-56', 'w-64'];
            return (
              <div
                key={crypto.randomUUID()}
                className={cn(
                  'mx-2 flex h-8 items-center justify-between px-2'
                )}
              >
                <div className='flex items-center gap-2'>
                  <Skeleton className='size-5 rounded-sm' />
                  <Skeleton className={cn('size-4 rounded-full', length[i])} />
                </div>
                <Skeleton className='mr-1 size-6 rounded-full' />
              </div>
            );
          })}
        {!isPending &&
          !isLoadingFiles &&
          folders?.list.map((folder) => (
            <div
              className={cn(
                'mx-2 flex items-center justify-between rounded-full px-2 hover:bg-secondary',
                highlightedFolder === folder.id && 'bg-secondary'
              )}
              key={folder.id}
            >
              <button
                className='grow'
                onDoubleClick={() => {
                  setBreadcrumbs((prev) => [...prev, folder]);
                  setCurrentFolder(folder);
                  setHighlightedFolder(folder.id);
                }}
                onClick={() => {
                  if (highlightedFolder === folder.id) {
                    setHighlightedFolder(undefined);
                  } else {
                    setHighlightedFolder(folder.id);
                  }
                }}
              >
                <div className='flex items-center gap-2'>
                  {folder.private ? (
                    <Icons.privateFolder
                      style={{ color: `#${folder.color}` || undefined }}
                      className='size-5'
                    />
                  ) : (
                    <FolderIcon
                      style={{ color: `#${folder.color}` || undefined }}
                      className='size-5'
                    />
                  )}
                  <div className='max-w-sm truncate text-sm'>{folder.name}</div>
                </div>
              </button>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant={'ghost'}
                    size={'icon-sm'}
                    className='rounded-full'
                    onClick={() => {
                      setBreadcrumbs((prev) => [...prev, folder]);
                      setCurrentFolder(folder);
                      setHighlightedFolder(folder.id);
                    }}
                  >
                    <ArrowRightIcon className='size-4' />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Visa innehåll</TooltipContent>
              </Tooltip>
            </div>
          ))}
        {!isLoadingFiles &&
          !isPending &&
          files?.list.map((file) => (
            <Tooltip delayDuration={700} key={file.id}>
              <TooltipTrigger asChild>
                <div
                  className={cn(
                    'mx-2 flex items-center justify-between rounded-full px-2 py-1 opacity-50 hover:bg-secondary'
                  )}
                >
                  <div className='grow'>
                    <div className='flex items-center gap-2'>
                      <GetIcon item={{ ...file, type: 'file' }} size='small' />
                      <div className='max-w-sm truncate text-sm'>
                        {file.originalName}
                      </div>
                    </div>
                  </div>
                </div>
              </TooltipTrigger>
              <TooltipContent className='text-xs'>
                Objektet {file.originalName} är inte en mapp
              </TooltipContent>
            </Tooltip>
          ))}
      </div>
      <div className='flex items-center justify-between border-t p-4'>
        <CreateNewFolder parentFolder={currentFolder} />
        <div className='flex items-center gap-2'>
          <Button onClick={closeModal} variant={'outline'}>
            Avbryt
          </Button>
          <Button
            onClick={onSubmit}
            disabled={
              !highlightedFolder ||
              highlightedFolder === contentData.file.folder?.id
            }
          >
            Flytta
          </Button>
        </div>
      </div>
    </>
  );
}

export const getMoveFileModalContent = (props: Props): ModalContent => {
  return {
    title: `Flytta ${props.contentData.title ?? 'laddar...'}`,
    body: <MoveFileModal {...props} />,
    closeButton: true,
    contentClassName: 'p-0 gap-0',
    headerClassName: 'px-4 pt-4 pb-2',
  };
};

function CreateNewFolder({
  parentFolder,
}: {
  parentFolder: RelationalFieldValue<Folder>;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const form = useForm({
    config: FolderConfig,
    defaultValues: getCleanFolder(),
    preMutatedValues: {
      ...(parentFolder?.notationContentType && {
        notationContentType: parentFolder.notationContentType,
      }),
      ...(parentFolder?.notationObjectId && {
        notationObjectId: parentFolder.notationObjectId,
      }),
    },
  });
  const { mutateAsync } = useCreateFolder({});
  const { addToast } = useToast();
  const onSubmit = () => {
    const data = form.getDirtyData();
    addToast({
      type: 'promise',
      promise: mutateAsync({
        body: {
          ...(parentFolder?.id && {
            folder: { id: parentFolder.id },
          }),
          ...data,
        },
      }),
      loading: 'Skapar mapp...',
      success: () => {
        form.reset();
        setIsOpen(false);
        return 'Mappen skapades';
      },
      error: (error: ErrorResponse) => {
        const field = handleFormErrors(error, form);
        if (field) {
          return field;
        }
        return 'Något gick fel';
      },
    });
  };
  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <Tooltip>
        <TooltipTrigger asChild>
          <AlertDialogTrigger asChild>
            <Button variant={'ghost'} size={'icon-sm'} className='rounded-full'>
              <FolderPlusIcon className='size-5' />
            </Button>
          </AlertDialogTrigger>
        </TooltipTrigger>
        <TooltipContent>Skapa mapp</TooltipContent>
      </Tooltip>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className={'flex items-center justify-between'}>
            Skapa mapp
            <AlertDialogCancel>
              <XMarkIcon className='size-4' />
            </AlertDialogCancel>
          </AlertDialogTitle>
        </AlertDialogHeader>
        <Form {...form}>
          <form
            className='grid gap-4'
            onSubmit={(e) => {
              form.clearErrors();
              form.handleSubmit(onSubmit)(e);
            }}
          >
            <DynamicField formField={form.fields.name} />
            <div className='flex justify-end'>
              <Button type='submit'>Spara</Button>
            </div>
          </form>
        </Form>
      </AlertDialogContent>
    </AlertDialog>
  );
}
