import { createInvoiceCreditationConnection } from '@/config/accounting/creditations/invoicecreditationconnection/create';
import { createInvoice } from '@/config/accounting/invoices/invoice/create';
import { deleteInvoice } from '@/config/accounting/invoices/invoice/delete';
import { getInvoiceRecipientList } from '@/config/accounting/invoices/invoicerecipient/server';
import { getInvoiceRowList } from '@/config/accounting/invoices/invoicerow/server';
import { handleFormErrors } from '@/hooks/useForm/utils';
import { raise, toMoneyString, typeGuard } from '@/lib/utils';
import { BulkMutate } from '@/requests/bulk';
import type { ErrorResponse } from '@/requests/types';
import {
  BellIcon,
  CheckCircleIcon,
  ClockIcon,
  DocumentPlusIcon,
  LockClosedIcon,
  PaperAirplaneIcon,
  PencilIcon,
} from '@heroicons/react/16/solid';
import {
  OrganizationUserConfig,
  OrganizationUserContactPersonConfig,
  SubTenantConfig,
  TenantConfig,
  type Address,
  type DebtInvoice,
  type EventLog,
  type FieldValue,
  type Invoice,
  type InvoiceCreditationConnection,
  type InvoicePayment,
  type InvoiceRecipient,
  type InvoiceRow,
  type OrganizationUser,
  type OrganizationUserContactPerson,
  type RelationalFieldValue,
  type SubTenant,
  type Tenant,
} from '@pigello/pigello-matrix';
import { DateTime } from 'luxon';
import { toast } from 'sonner';
import type { EventIdentifier } from '../components/events/utils';

type IActualRecipient = Tenant &
  OrganizationUser &
  OrganizationUserContactPerson &
  SubTenant;

type InvoiceEventType =
  | 'created'
  | 'updated'
  | 'attested'
  | 'sent'
  | 'overdue'
  | 'closed'
  | 'reminder'
  | 'debt'
  | 'reminder-future'
  | 'debt-future';

export type InvoiceEvent = {
  title: string;
  content?: string;
  date: DateTime;
  icon?: React.ReactNode;
  eventIdentifier: InvoiceEventType;
};

export function getValue<T>(obj: T, attrList: (keyof IActualRecipient)[]) {
  for (const attr of attrList) {
    if (obj && typeof obj === 'object' && attr in obj && obj[attr as keyof T]) {
      return obj[attr as keyof T];
    }
  }
  return undefined;
}

export function getActualRecipientFields(recipient: InvoiceRecipient) {
  // const actualRecipient = {
  //   ...recipient.tenant,
  //   type: TenantConfig.modelName,
  // } ?? {
  //     ...recipient.subTenant,
  //     type: SubTenantConfig.modelName,
  //   } ?? {
  //     ...recipient.organizationUser,
  //     type: OrganizationUserConfig.modelName,
  //   } ?? {
  //     ...recipient.organizationUserContactPerson,
  //     type: OrganizationUserContactPersonConfig.modelName,
  //   };

  const actualRecipient = recipient.tenant
    ? { ...recipient.tenant, type: TenantConfig.modelName }
    : recipient.subTenant
      ? { ...recipient.subTenant, type: SubTenantConfig.modelName }
      : recipient.organizationUser
        ? {
            ...recipient.organizationUser,
            type: OrganizationUserConfig.modelName,
          }
        : recipient.organizationUserContactPerson
          ? {
              ...recipient.organizationUserContactPerson,
              type: OrganizationUserContactPersonConfig.modelName,
            }
          : ({ type: 'unset' } as const);

  const invoiceDistributionInstruction: number[][] | undefined = typeGuard(
    recipient,
    'invoiceDistributionInstruction'
  )
    ? recipient?.invoiceDistributionInstruction
    : undefined;
  const reminderDistributionInstruction: number[][] | undefined = typeGuard(
    recipient,
    'reminderDistributionInstruction'
  )
    ? recipient?.reminderDistributionInstruction
    : undefined;
  const debtDistributionInstruction: number[][] | undefined = typeGuard(
    recipient,
    'debtDistributionInstruction'
  )
    ? recipient?.debtDistributionInstruction
    : undefined;

  return {
    id: getValue(actualRecipient, ['id']) as string | undefined,
    email:
      recipient.email ??
      (getValue(actualRecipient, [
        'invoiceEmail',
        'email',
      ]) as FieldValue<string>),
    name:
      recipient.name ??
      (getValue(actualRecipient, ['communicationName']) as FieldValue<string>),
    address:
      recipient.postalAddress ??
      (getValue(actualRecipient, [
        'invoiceAddress',
      ]) as RelationalFieldValue<Address>),
    orgNo:
      recipient.orgNo ??
      (getValue(actualRecipient, ['orgNo']) as FieldValue<string>),
    type: actualRecipient.type,
    invoiceDistributionInstruction,
    reminderDistributionInstruction,
    debtDistributionInstruction,
    ssn:
      recipient.ssn ??
      (getValue(actualRecipient, ['ssn']) as FieldValue<string>),
  };
}

export const getReminderStartDate = (invoice: Invoice) => {
  if (!invoice.reminderAmount || invoice.reminderAmount === 0)
    return DateTime.fromISO(invoice.dueDate);

  if (invoice.reminderDistributionPushedUntil)
    return DateTime.fromISO(invoice.reminderDistributionPushedUntil);
  const daysAfterDueDate = invoice.reminderDaysAfterDueDate;
  const dueDate = DateTime.fromISO(invoice.dueDate);
  const reminderStartDate = dueDate.plus({
    days: daysAfterDueDate ?? undefined,
  });

  return reminderStartDate;
};

export const getReminderEndDate = (invoice: Invoice) => {
  if (!invoice.reminderAmount || invoice.reminderAmount === 0)
    return DateTime.fromISO(invoice.dueDate);

  const reminderStartDate = getReminderStartDate(invoice);

  const numReminders = invoice.reminderAmount;

  const daysInReminderState =
    numReminders * (invoice.reminderExpiresInDays ?? 0) +
    (invoice.reminderDaysAfterDueDate ?? 0) * (numReminders - 1);

  const endDate = reminderStartDate.plus({ days: daysInReminderState });
  return endDate;
};

export const getDebtStartDate = (invoice: Invoice) => {
  if (invoice.debtDistributionPushedUntil)
    return DateTime.fromISO(invoice.debtDistributionPushedUntil);
  const daysAfterDueDate = invoice.reminderDaysAfterDueDate;
  const dueDate = DateTime.fromISO(invoice.dueDate);
  const reminderStartDate = dueDate.plus({
    days: daysAfterDueDate ?? undefined,
  });

  return reminderStartDate;
};

export function buildEvents(
  invoice: Invoice | undefined,
  pigelloEvents: EventLog[] | undefined,
  debtInvoices?: DebtInvoice[]
) {
  const events: InvoiceEvent[] = [];
  const now = DateTime.now();

  if (!invoice || !pigelloEvents) return {};

  const invoiceDueDate = DateTime.fromISO(invoice.dueDate);
  const reminderStartDate = invoice.reminderDistributionPushedUntil
    ? DateTime.fromISO(invoice.reminderDistributionPushedUntil)
    : invoiceDueDate.plus({ day: invoice.reminderDaysAfterDueDate || 0 });

  // created
  events.push({
    title: `${invoice.rentalInvoice ? 'Avi' : 'Faktura'} skapades`,
    date: DateTime.fromISO(invoice.createdAt),
    icon: <DocumentPlusIcon className='size-4 min-w-[16px]' />,
    eventIdentifier: 'created',
  });

  pigelloEvents
    .filter((evt) => {
      const eventKind = evt.eventIdentifier.match(
        /(created|updated|deleted|anonymized|changed)/
      )?.[0] as EventIdentifier;

      return eventKind === 'updated';
    })
    .forEach((updateEvent) => {
      events.push({
        title: `${invoice.rentalInvoice ? 'Avi' : 'Faktura'} uppdaterades`,
        date: DateTime.fromISO(updateEvent.createdAt),
        icon: <PencilIcon className='size-4 min-w-[16px]' />,
        eventIdentifier: 'updated',
      });
    });

  if (invoice.attestedDate) {
    events.push({
      title: `${invoice.rentalInvoice ? 'Avi' : 'Faktura'} attesterades`,
      date: DateTime.fromISO(invoice.attestedDate),
      icon: <LockClosedIcon className='size-4 min-w-[16px]' />,
      content: `Observera att detta avser attestdatumet på ${invoice.rentalInvoice ? 'avin' : 'fakturan'} och inte datumet som uppdateringen skedde.`,
      eventIdentifier: 'attested',
    });
  }

  if (invoice.closedTime) {
    events.push({
      title: `${invoice.rentalInvoice ? 'Avi' : 'Faktura'} stängdes`,
      date: DateTime.fromISO(invoice.closedTime),
      icon: <CheckCircleIcon className='size-4 min-w-[16px]' />,
      eventIdentifier: 'closed',
    });
  }

  if (invoice.invoiceDate) {
    const invoiceDate = DateTime.fromISO(invoice.invoiceDate);
    events.push({
      title: `${invoice.rentalInvoice ? 'Avi' : 'Faktura'} ${invoiceDate > now ? 'ställs ut' : 'ställdes ut'}`,
      date: invoiceDate,
      icon: <PaperAirplaneIcon className='size-4 min-w-[16px]' />,
      eventIdentifier: 'sent',
    });
  }
  if (invoice.dueDate) {
    const dueDate = DateTime.fromISO(invoice.dueDate);
    events.push({
      title: `${invoice.rentalInvoice ? 'Avi' : 'Faktura'} ${dueDate > now ? 'förfaller' : 'förföll'}`,
      date: dueDate,
      icon: <ClockIcon className='size-4 min-w-[16px]' />,
      eventIdentifier: 'overdue',
    });
  }

  // reminder info
  if ((invoice.reminderAmount ?? 0) > 0) {
    if (!invoice.closedTime) {
      const sortedReminderInvoices =
        debtInvoices
          ?.sort((a, b) => a.debtDate.localeCompare(b.debtDate))
          .filter((debtInvoice) => debtInvoice.kind === 0) ?? [];
      for (const [idx, debtInvoice] of sortedReminderInvoices.entries()) {
        events.push({
          title: `Påminnelse nr ${idx + 1}`,
          date: DateTime.fromISO(debtInvoice.debtDate),
          icon: <BellIcon className='size-4 min-w-[16px]' />,
          content: `Påminnelseavgift på ${toMoneyString(debtInvoice.fee)} ${`lades på ${invoice.rentalInvoice ? 'avin' : 'fakturan'}.`}`,
          eventIdentifier: 'reminder',
        });
      }

      const hasSentAllReminderInvoices =
        sortedReminderInvoices.length >= (invoice.reminderAmount ?? 0);
      const reminderAmountLeft =
        invoice.reminderAmount ?? 0 - sortedReminderInvoices.length;
      const lastReminderInvoice = sortedReminderInvoices.at(-1);
      const lastReminderInvoiceDate = lastReminderInvoice
        ? DateTime.fromISO(lastReminderInvoice.debtDate)
        : undefined;

      if (
        !hasSentAllReminderInvoices &&
        !invoice.automaticDebtInvoicingPaused
      ) {
        Array.from(Array(reminderAmountLeft).keys()).map((_, idx) => {
          const daysAfterDueDate = invoice.reminderDaysAfterDueDate;
          const daysBetweenReminders = invoice.reminderExpiresInDays;
          // current delay is latest due date + days after due date
          const currentDelayFromDueDate =
            idx === 0
              ? 0
              : (daysAfterDueDate ?? 0) + (daysBetweenReminders ?? 0);

          const reminderDate = (
            lastReminderInvoiceDate ?? reminderStartDate
          ).plus({
            days: currentDelayFromDueDate,
          });

          if (reminderDate > now) {
            events.push({
              title: `Påminnelse nr ${idx + 1}`,
              date: reminderDate,
              icon: <BellIcon className='size-4 min-w-[16px]' />,
              content: `Påminnelseavgift på ${toMoneyString(invoice.reminderFee)} ${`kommer läggas på ${invoice.rentalInvoice ? 'avin' : 'fakturan'}.`}`,
              eventIdentifier: 'reminder-future',
            });
          }
        });
      }
    }
  }
  // debt info
  if ((invoice.debtReminderAmount ?? 0) > 0) {
    if (!invoice.closedTime) {
      const sortedDebtInvoices =
        debtInvoices
          ?.sort((a, b) => a.debtDate.localeCompare(b.debtDate))
          .filter((debtInvoice) => debtInvoice.kind === 1) ?? [];
      for (const [idx, debtInvoice] of sortedDebtInvoices.entries()) {
        events.push({
          title: `Krav nr ${idx + 1}`,
          date: DateTime.fromISO(debtInvoice.debtDate),
          icon: <BellIcon className='size-4 min-w-[16px]' />,
          content: `Kravavgift på ${toMoneyString(debtInvoice.fee)} ${`lades på ${invoice.rentalInvoice ? 'avin' : 'fakturan'}.`}`,
          eventIdentifier: 'debt',
        });
      }
      const debtStartDate = invoice.debtDistributionPushedUntil
        ? DateTime.fromISO(invoice.debtDistributionPushedUntil)
        : getReminderEndDate(invoice).plus({
            days: invoice.debtReminderDaysAfterDueDate ?? 0 ?? undefined,
          });
      const hasSentAllDebtInvoices =
        sortedDebtInvoices.length >= (invoice.debtReminderAmount ?? 0);
      const debtReminderAmountLeft =
        invoice.debtReminderAmount ?? 0 - sortedDebtInvoices.length;
      const lastDebtInvoice = sortedDebtInvoices.at(-1);
      const lastDebtInvoiceDate = lastDebtInvoice
        ? DateTime.fromISO(lastDebtInvoice.debtDate)
        : undefined;

      if (!hasSentAllDebtInvoices && !invoice.automaticDebtInvoicingPaused) {
        Array.from(Array(debtReminderAmountLeft).keys()).map((_, idx) => {
          const daysAfterDueDate = invoice.debtReminderDaysAfterDueDate;
          const daysBetweenReminders = invoice.debtReminderExpiresInDays;

          const currentDelayFromDueDate =
            idx === 0
              ? 0
              : (daysAfterDueDate ?? 0) + (daysBetweenReminders ?? 0);
          const debtDate = (lastDebtInvoiceDate ?? debtStartDate).plus({
            days: currentDelayFromDueDate,
          });
          if (debtDate > now) {
            events.push({
              title: `Krav nr ${idx + 1}`,
              date: debtDate,
              icon: <BellIcon className='size-4 min-w-[16px]' />,
              content: `Kravavgift på ${toMoneyString(invoice.reminderFee)} ${`kommer läggas på ${invoice.rentalInvoice ? 'avin' : 'fakturan'}.`}`,
              eventIdentifier: 'debt-future',
            });
          }
        });
      }
    }
  }

  const sorted = events.sort((a, b) =>
    a.date > b.date ? 1 : b.date > a.date ? -1 : 0
  );

  const pastEvents = sorted.filter((e) => e.date < now);
  const futureEvents = sorted.filter((e) => e.date >= now);

  return { pastEvents, futureEvents, allEvents: sorted };
}

export function getInvoiceTotalDebitIncVat(
  rows: InvoiceRow[] | undefined,
  adminFee: number | undefined
) {
  return rows?.reduce((acc, cur) => {
    const debitSum =
      (cur.unitAmount ?? 1) * (cur.unitPrice ?? 0) + (adminFee ?? 0);

    const vat = debitSum * (1 + (cur.vat ?? 0) / 100) - debitSum;
    const total = debitSum + vat;

    return acc + total;
  }, 0);
}

export function getInvoiceTotalVat(
  rows: InvoiceRow[] | undefined,
  adminFee: number | undefined
) {
  return rows?.reduce((acc, cur) => {
    const debitSum =
      (cur.unitAmount ?? 1) * (cur.unitPrice ?? 0) + (adminFee ?? 0);

    const vat = debitSum * (1 + (cur.vat ?? 0) / 100) - debitSum;

    return acc + vat;
  }, 0);
}

export function getInvoiceTotalDebitExVat(
  rows: InvoiceRow[] | undefined,
  adminFee: number | undefined
) {
  return rows?.reduce((acc, cur) => {
    const debitSum =
      (cur.unitAmount ?? 1) * (cur.unitPrice ?? 0) + (adminFee ?? 0);
    return acc + debitSum;
  }, 0);
}

export function getInvoiceTotalPaid(
  matchedPayments: InvoicePayment[] | undefined
) {
  return matchedPayments
    ?.filter((payment) => !payment.annulled)
    .reduce((acc, cur) => acc + (cur.value ?? 0), 0);
}

export async function createCreditInvoiceAndConnect({
  targetInvoice,
  paymentData,
}: {
  targetInvoice: Invoice;
  paymentData: Partial<InvoiceCreditationConnection>;
}) {
  let creditInvoice;

  // create base
  try {
    const newInvoice: Partial<Invoice> = {
      invoiceDate: DateTime.now().toFormat('yyyy-MM-dd'),
      company: targetInvoice.company,
      dueDate: DateTime.now().plus({ days: 14 }).toFormat('yyyy-MM-dd'),
      mainPeriodEnd: targetInvoice.mainPeriodEnd,
      mainPeriodStart: targetInvoice.mainPeriodStart,
      yourReference: targetInvoice.yourReference,
      ourReference: targetInvoice.ourReference,
      mergeNonBaseRentInView: targetInvoice.mergeNonBaseRentInView,
      mergeIndexInView: targetInvoice.mergeIndexInView,
      rentalInvoice: targetInvoice.rentalInvoice,
      adminFeeVatMethod: targetInvoice.adminFeeVatMethod,
    };

    creditInvoice = await createInvoice({ body: newInvoice });
  } catch (e) {
    const err = handleFormErrors(e as ErrorResponse);
    return toast.error(`Lyckades ej skapa kredit: ${err}`);
  }

  const creditInvoiceId = creditInvoice.id;

  //Create credit invoice rows
  try {
    const { list: rows } = await getInvoiceRowList({
      queryParams: {
        page: 1,
        pageSize: 500,
        filters: {
          invoice: { noop: targetInvoice.id },
        },
      },
    });

    const bulk = await new BulkMutate<InvoiceRow>('invoicerow').init();

    rows.forEach((row) => {
      const newRow: Partial<InvoiceRow> = {
        article: row.article,
        invoice: { id: creditInvoiceId },
        periodStart: row.periodStart,
        periodEnd: row.periodEnd,
        title: row.title,
        unit: row.unit,
        unitAmount: row.unitAmount,
        unitPrice: (row.unitPrice ?? 0) * -1,
        vat: row.vat,
        usedArticleAccount: row.usedArticleAccount,
      };

      bulk.push(newRow);
    });

    bulk.saveOnlyMutations(false);

    const { err } = await bulk.save();

    if (err) raise('Kunde ej spara debiteringsrader på kredit');
  } catch (e) {
    const err = handleFormErrors(e as ErrorResponse);

    await deleteInvoice({ id: creditInvoiceId });

    return toast.error(`Lyckades ej skapa debiteringsrader på kredit: ${err}`);
  }

  // create parties

  try {
    const { list: parties } = await getInvoiceRecipientList({
      queryParams: {
        page: 1,
        pageSize: 500,
        filters: {
          invoice: { noop: targetInvoice.id },
        },
      },
    });

    const partyBulk = await new BulkMutate<InvoiceRecipient>(
      'invoicerecipient'
    ).init();

    parties.forEach((party) => {
      const newRow: Partial<InvoiceRecipient> = {
        invoice: { id: creditInvoiceId },
        tenant: party.tenant,
        subTenant: party.subTenant,
        organizationUser: party.organizationUser,
        organizationUserContactPerson: party.organizationUserContactPerson,
        viewOnly: party.viewOnly,
        ssn: party.ssn,
        orgNo: party.orgNo,
        email: party.email,
        phoneNumber: party.phoneNumber,
        customPostalAddress: party.customPostalAddress,
        name: party.name,
        priority: party.priority,
        invoiceDistributionInstruction: party.invoiceDistributionInstruction,
        reminderDistributionInstruction: party.reminderDistributionInstruction,
        debtDistributionInstruction: party.debtDistributionInstruction,
        postalAddress: party.postalAddress,
      };

      partyBulk.push(newRow);
    });

    partyBulk.saveOnlyMutations(false);

    const { err } = await partyBulk.save();
    if (err) raise('Kunde ej spara mottagare på kredit');
  } catch (e) {
    const err = handleFormErrors(e as ErrorResponse);

    await deleteInvoice({ id: creditInvoiceId });

    return toast.error(`Lyckades ej skapa mottagare på kredit: ${err}`);
  }

  // now we have the entire credit invoice, time to create the connection

  try {
    const connectionData: Partial<InvoiceCreditationConnection> = {
      ...paymentData,
      targetInvoice,
      sourceInvoice: creditInvoice,
    };

    await createInvoiceCreditationConnection({ body: connectionData });
  } catch (err) {
    await deleteInvoice({ id: creditInvoiceId });

    return toast.error(
      `Lyckades ej koppla kredit mot ${targetInvoice.rentalInvoice ? 'avin' : 'fakturan'}: ${err}`
    );
  }

  return creditInvoice;
}
