import { StatusMap } from '@/components/data-table/constants';
import { Badge } from '@/components/ui/badge';
import type { Tenant } from '@pigello/pigello-matrix';

export function TenantBadge({ tenant }: { tenant: Tenant }) {
  if (tenant.status == null) {
    return null;
  }

  return (
    <Badge variant={StatusMap.tenant[tenant.status].color}>
      {StatusMap.tenant[tenant.status].text}
    </Badge>
  );
}
