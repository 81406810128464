import {
  defaultNumberFilter,
  defaultSelectFilter,
  defaultStringFilter,
  type IFilter,
} from '@/components/table-filters/constants';
import { CommonAreaConfig } from '@pigello/pigello-matrix';
import baseFilters, {
  baseAddressFilters,
  baseBuildingFilter,
  baseGlobalRealEstatesFilter,
  baseTagFilter,
} from '../filters';

const commonAreaFilters: IFilter[] = [
  ...baseBuildingFilter,
  ...baseGlobalRealEstatesFilter,
  {
    title: CommonAreaConfig.fields.category.verboseName,
    externalFieldName: CommonAreaConfig.fields.category.externalFieldName,
    choices: CommonAreaConfig.fields.category.choices,
    ...defaultSelectFilter,
  },
  {
    title: CommonAreaConfig.fields.popularName.verboseName,
    externalFieldName: CommonAreaConfig.fields.popularName.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: CommonAreaConfig.fields.customId.verboseName,
    externalFieldName: CommonAreaConfig.fields.customId.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: CommonAreaConfig.fields.usableArea.verboseName,
    externalFieldName: CommonAreaConfig.fields.usableArea.externalFieldName,
    ...defaultNumberFilter,
  },
  {
    title: CommonAreaConfig.fields.biArea.verboseName,
    externalFieldName: CommonAreaConfig.fields.biArea.externalFieldName,
    ...defaultNumberFilter,
  },
  {
    title: CommonAreaConfig.fields.temperedArea.verboseName,
    externalFieldName: CommonAreaConfig.fields.temperedArea.externalFieldName,
    ...defaultNumberFilter,
  },

  ...baseAddressFilters,
  ...baseTagFilter,
  ...baseFilters,
];

export default commonAreaFilters;
