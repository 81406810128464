import { DynamicField } from '@/components/form/dynamic-field';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Form } from '@/components/ui/form';
import { Loader } from '@/components/ui/loader';
import { useGetInvoice } from '@/config/accounting/invoices/invoice/client';
import { useMutateInvoice } from '@/config/accounting/invoices/invoice/mutate';
import { useForm } from '@/hooks/useForm';
import { handleFormErrors } from '@/hooks/useForm/utils';
import { useToast } from '@/hooks/useToast';
import setFormValue from '@/lib/set-form-value';
import { raise } from '@/lib/utils';
import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import type { ErrorResponse } from '@/requests/types';
import { XMarkIcon } from '@heroicons/react/16/solid';
import { InvoiceConfig, getCleanInvoice } from '@pigello/pigello-matrix';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

export type InvoiceAttachmentResponse = Promise<boolean>;

export type InvoiceAttachmentContentData = {
  invoiceId?: string;
};

interface Props extends GetModalContentProps {
  closeModal: () => void;
  contentData: InvoiceAttachmentContentData;
}

function InvoiceAttachmentModal({ closeModal, contentData }: Props) {
  const { data: invoice, isLoading: invoiceLoading } = useGetInvoice({
    id: contentData.invoiceId ?? raise('No invoice id'),
  });

  const [replaceActive, setReplaceActive] = useState(false);

  const hasAttachment = invoice?.attachment;

  const { mutateAsync, isPending } = useMutateInvoice({});
  const { addToast } = useToast();
  const queryClient = useQueryClient();
  const form = useForm({
    defaultValues: getCleanInvoice(),
    values: invoice,
    config: InvoiceConfig,
  });

  const onSubmit = () => {
    const formData = form.getDirtyData();
    addToast({
      type: 'promise',
      promise: mutateAsync({
        id: contentData.invoiceId as string,
        body: formData,
      }),
      loading: 'Sparar...',
      success: () => {
        form.reset();
        queryClient.invalidateQueries({
          queryKey: [InvoiceConfig.modelName, { id: invoice?.id }],
        });
        queryClient.invalidateQueries({
          queryKey: [InvoiceConfig.modelName, 'list'],
        });
        closeModal();
        return 'Sparade';
      },
      error: (error: ErrorResponse) => {
        const field = handleFormErrors(error, form);

        return `Något gick fel: ${field}`;
      },
    });
  };
  return (
    <Dialog open onOpenChange={() => closeModal()}>
      <DialogContent className='max-w-3xl p-0 sm:min-w-[768px]'>
        <DialogHeader className='flex w-full flex-row items-center justify-between border-b px-4 py-2'>
          {invoiceLoading ? (
            <DialogTitle>Laddar...</DialogTitle>
          ) : (
            <DialogTitle>
              Hantera bilaga på {invoice?.rentalInvoice ? 'avi' : 'faktura'}
            </DialogTitle>
          )}
          <Button
            variant='outline'
            size={'icon-sm'}
            type='button'
            onClick={closeModal}
          >
            <XMarkIcon className='size-4' />
          </Button>
        </DialogHeader>

        {invoiceLoading ? (
          <Loader />
        ) : hasAttachment && !replaceActive ? (
          <div className='flex flex-col items-center gap-4 p-12'>
            <p>
              En bilaga finns redan på{' '}
              {invoice?.rentalInvoice ? 'avin' : 'fakturan'}. En{' '}
              {invoice?.rentalInvoice ? 'avi' : 'faktura'} kan enbart ha en
              bilaga.
            </p>
            <Button
              variant={'secondary'}
              onClick={() => {
                setFormValue(form, 'attachment', null);
                setReplaceActive(true);
              }}
            >
              Byt bilaga
            </Button>
          </div>
        ) : (
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className='grid max-h-[calc(var(--window-keeper-height)-135px)] min-h-[min(400px,calc(var(--window-keeper-height)-175px))] gap-2 overflow-auto p-4'>
                <DynamicField formField={form.fields.attachment} />
              </div>
              <DialogFooter className='border-t px-4 py-2'>
                <Button type='submit' disabled={isPending}>
                  Spara
                </Button>
                <Button type='button' variant={'outline'} onClick={closeModal}>
                  Avbryt
                </Button>
              </DialogFooter>
            </form>
          </Form>
        )}
      </DialogContent>
    </Dialog>
  );
}

export const getInvoiceAttachmentContent = (props: Props): ModalContent => {
  return {
    title: 'Skicka avi',
    body: <InvoiceAttachmentModal {...props} />,
    isCloseOnEscape: true,
    closeButton: true,
    externalModalHandling: true,
  };
};
