import {
  defaultRelationFilter,
  type IFilter,
} from '@/components/table-filters/constants';
import {
  ParkingSpotConfig,
  ParkingSpotContractConfig,
} from '@pigello/pigello-matrix';
import baseFilters, {
  baseGlobalRealEstatesFilter,
  baseTagFilter,
} from '../../filters';
import baseContractFilters from '../components/base-contract-filters';

const parkingSpotContractFilters: IFilter[] = [
  {
    title: ParkingSpotContractConfig.fields.space.verboseName,
    relationModelName: ParkingSpotConfig.modelName,
    externalFieldName: ParkingSpotContractConfig.fields.space.externalFieldName,
    ...defaultRelationFilter,
  },
  ...baseGlobalRealEstatesFilter,
  ...baseContractFilters,
  ...baseTagFilter,
  ...baseFilters,
];

export default parkingSpotContractFilters;
