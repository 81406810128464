'use client';

import type { ColumnDef } from '@tanstack/react-table';

import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header';
import { Checkbox } from '@/components/ui/checkbox';

import { TableActions } from '@/components/data-table/table-actions';
import { TableTags } from '@/components/data-table/table-tags';
import { Badge } from '@/components/ui/badge';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { toReadableDate } from '@/lib/dates';
import { Urls } from '@/lib/urls';
import { toMoneyString } from '@/lib/utils';
import { InformationCircleIcon } from '@heroicons/react/16/solid';
import type { Invoice, ModelName } from '@pigello/pigello-matrix';
import {
  BookkeepingAccountsConfigConfig,
  CompanyConfig,
  InvoiceConfig,
} from '@pigello/pigello-matrix';
import { DropdownList } from '../../components/dropdown-list';
import { DebitRows } from './components/debit-rows-popover';
import { InvoiceStatus } from './components/invoice-status';
import { OriginatesFromObject } from './components/originates-from-object';

export const columns: ColumnDef<Invoice>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label='Select all'
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label='Select row'
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'rentalInvoice',
    meta: {
      columnVisibilityName: 'Typ',
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='flex items-center gap-1'>
        <Badge
          variant={
            (row.original.invoiceValuesMeta?.total_value ?? 0) > -1
              ? row.original.rentalInvoice
                ? 'blue'
                : 'green'
              : 'destructive'
          }
        >
          {(row.original.invoiceValuesMeta?.total_value ?? 0) > -1
            ? row.original.rentalInvoice
              ? 'Avi'
              : 'Faktura'
            : row.original.rentalInvoice
              ? 'Kreditavi'
              : 'Kreditfaktura'}
        </Badge>
      </div>
    ),
  },

  {
    accessorKey: 'status',
    enableSorting: false,
    meta: {
      columnVisibilityName: 'Status',
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => <InvoiceStatus invoice={row.original} />,
  },
  {
    id: 'tenant',
    enableSorting: false,
    meta: {
      columnVisibilityName: 'Mottagare',
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {/* @ts-expect-error custom temp solution TODO */}
        {row.original.invoiceRecipients?.[0]?.tenant?.communication_name ??
          '-'}{' '}
        {row.original.invoiceRecipients?.length > 1
          ? `+ ${row.original.invoiceRecipients?.length - 1}`
          : ''}
      </div>
    ),
  },
  {
    accessorKey: 'invoiceNumber',
    enableSorting: false,
    meta: {
      columnVisibilityName: InvoiceConfig.fields.invoiceNumber.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {row.original.invoiceNumber ? `#${row.original.invoiceNumber}` : '-'}
      </div>
    ),
    aggregatedCell: ({ row }) => {
      let count = 0;
      for (const leafRow of row.getLeafRows()) {
        if (!leafRow.getIsGrouped()) count++;
      }
      return count;
    },
  },
  {
    accessorKey: 'company',
    enableSorting: false,
    meta: {
      columnVisibilityName: InvoiceConfig.fields.company.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <DropdownList
        ids={[row.original.company?.id]}
        initialData={row.original.company}
        detailModalLinkUrl={(id) => Urls.detail.company.overview(id)}
        config={CompanyConfig}
        fields={['name']}
      />
    ),
  },
  {
    id: 'invoice_net',
    enableSorting: false,
    meta: {
      columnVisibilityName: 'Belopp Netto (SEK)',
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      const net =
        (row.original.invoiceValuesMeta?.total_value ?? 0) -
        (row.original.invoiceValuesMeta?.total_vat ?? 0);

      return <div className='whitespace-nowrap'>{toMoneyString(net)}</div>;
    },
  },
  {
    id: 'invoice_gross',
    enableSorting: false,
    meta: {
      columnVisibilityName: 'Belopp Brutto (SEK)',
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      const gross = row.original.invoiceValuesMeta?.total_value ?? 0;

      return <div className='whitespace-nowrap'>{toMoneyString(gross)}</div>;
    },
  },
  {
    id: 'invoice_vat',
    enableSorting: false,
    meta: {
      columnVisibilityName: 'Varav Moms (SEK)',
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      const vat = row.original.invoiceValuesMeta?.total_vat ?? 0;
      const vat6 = row.original.invoiceValuesMeta?.total_6_vat ?? 0;
      const vat12 = row.original.invoiceValuesMeta?.total_12_vat ?? 0;
      const vat25 = row.original.invoiceValuesMeta?.total_25_vat ?? 0;

      return (
        <Tooltip>
          <TooltipTrigger>
            <div className='flex items-center whitespace-nowrap'>
              {toMoneyString(vat)}{' '}
              <InformationCircleIcon className='ml-1 size-4' />
            </div>
          </TooltipTrigger>
          <TooltipContent className='min-w-[400px]'>
            <div className='flex items-center justify-between'>
              <div>Moms:</div>
              <div>{toMoneyString(vat)}</div>
            </div>

            <div className='flex items-center justify-between'>
              <div>Moms 6%:</div>
              <div>{toMoneyString(vat6)}</div>
            </div>
            <div className='flex items-center justify-between'>
              <div>Moms 12%:</div>
              <div>{toMoneyString(vat12)}</div>
            </div>
            <div className='flex items-center justify-between'>
              <div>Moms 25%:</div>
              <div>{toMoneyString(vat25)}</div>
            </div>
          </TooltipContent>
        </Tooltip>
      );
    },
  },
  {
    accessorKey: 'paymentsSum',
    enableSorting: false,
    meta: {
      columnVisibilityName: 'Betalt belopp (SEK)',
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {toMoneyString(row.original.paymentsSum ?? 0)}
      </div>
    ),
  },
  {
    accessorKey: 'attestedDate',
    meta: {
      columnVisibilityName: 'Attesterad',
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {row.original.attestedDate ? 'Ja' : 'Nej'}
      </div>
    ),
  },
  {
    accessorKey: 'createdAt',
    meta: {
      columnVisibilityName: 'Skapad',
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {toReadableDate(row.original.createdAt)}
      </div>
    ),
  },
  {
    accessorKey: 'ocrNumber',
    meta: {
      columnVisibilityName: InvoiceConfig.fields.ocrNumber.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.ocrNumber ?? '-'}</div>
    ),
  },
  {
    accessorKey: 'invoiceDate',
    meta: {
      columnVisibilityName: InvoiceConfig.fields.invoiceDate.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {toReadableDate(row.original.invoiceDate)}
      </div>
    ),
  },
  {
    accessorKey: 'dueDate',
    meta: {
      columnVisibilityName: InvoiceConfig.fields.dueDate.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {toReadableDate(row.original.dueDate)}
      </div>
    ),
  },
  {
    accessorKey: 'mainPeriodStart',
    meta: {
      columnVisibilityName: InvoiceConfig.fields.mainPeriodStart.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {toReadableDate(row.original.mainPeriodStart)}
      </div>
    ),
  },
  {
    accessorKey: 'mainPeriodEnd',
    meta: {
      columnVisibilityName: InvoiceConfig.fields.mainPeriodEnd.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {toReadableDate(row.original.mainPeriodEnd)}
      </div>
    ),
  },
  {
    accessorKey: 'closedTime',
    meta: {
      columnVisibilityName: InvoiceConfig.fields.closedTime.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {toReadableDate(row.original.closedTime)}
      </div>
    ),
  },
  {
    id: 'debitRows',
    enableSorting: false,
    meta: {
      columnVisibilityName: 'Debiteringsrader',
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => <DebitRows invoice={row.original} />,
  },
  {
    accessorKey: 'originatesFromObjectId',
    meta: {
      initiallyHidden: true,
      columnVisibilityName:
        InvoiceConfig.fields.originatesFromObjectId.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,

    cell: ({ row }) => {
      const modelName = row.original.originatesFromContentType?.id?.split(
        '.'
      )[1] as ModelName;
      if (!row.original.originatesFromObjectId || !modelName) {
        return <div>-</div>;
      }
      return (
        <OriginatesFromObject
          modelName={modelName}
          id={row.original.originatesFromObjectId}
        />
      );
    },
  },

  {
    accessorKey: 'customId',
    meta: {
      columnVisibilityName: InvoiceConfig.fields.customId.verboseName,
      initiallyHidden: true,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.customId ?? '-'}</div>
    ),
  },
  {
    accessorKey: 'ourReference',
    meta: {
      columnVisibilityName: InvoiceConfig.fields.ourReference.verboseName,
      initiallyHidden: true,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {row.original.ourReference ?? '-'}
      </div>
    ),
  },
  {
    accessorKey: 'yourReference',
    meta: {
      columnVisibilityName: InvoiceConfig.fields.yourReference.verboseName,
      initiallyHidden: true,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {row.original.yourReference ?? '-'}
      </div>
    ),
  },
  {
    accessorKey: 'marking',
    meta: {
      columnVisibilityName: InvoiceConfig.fields.marking.verboseName,
      initiallyHidden: true,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.marking ?? '-'}</div>
    ),
  },
  {
    accessorKey: 'useRounding',
    meta: {
      columnVisibilityName: InvoiceConfig.fields.useRounding.verboseName,
      initiallyHidden: true,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {row.original.useRounding ? 'Ja' : 'Nej'}
      </div>
    ),
  },
  {
    accessorKey: 'adminFee',
    meta: {
      columnVisibilityName: InvoiceConfig.fields.adminFee.verboseName,
      initiallyHidden: true,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {toMoneyString(row.original.adminFee)}
      </div>
    ),
  },
  {
    accessorKey: 'adminFeeVatMethod',
    meta: {
      columnVisibilityName: InvoiceConfig.fields.adminFeeVatMethod.verboseName,
      initiallyHidden: true,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {InvoiceConfig.fields.adminFeeVatMethod.choices?.find(
          (c) => c.v === row.original.adminFeeVatMethod
        )?.d ?? '-'}
      </div>
    ),
  },
  {
    accessorKey: 'depositFee',
    meta: {
      columnVisibilityName: InvoiceConfig.fields.depositFee.verboseName,
      initiallyHidden: true,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {toMoneyString(row.original.depositFee)}
      </div>
    ),
  },
  {
    accessorKey: 'utilizeCustomerBalance',
    meta: {
      columnVisibilityName:
        InvoiceConfig.fields.utilizeCustomerBalance.verboseName,
      initiallyHidden: true,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {row.original.utilizeCustomerBalance ? 'Ja' : 'Nej'}
      </div>
    ),
  },
  {
    accessorKey: 'outstandingDebtViewType',
    meta: {
      columnVisibilityName:
        InvoiceConfig.fields.outstandingDebtViewType.verboseName,
      initiallyHidden: true,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {InvoiceConfig.fields.outstandingDebtViewType.choices?.find(
          (choice) => choice.v === row.original.outstandingDebtViewType
        )?.d ?? '-'}
      </div>
    ),
  },
  {
    accessorKey: 'graceDays',
    meta: {
      columnVisibilityName: InvoiceConfig.fields.graceDays.verboseName,
      initiallyHidden: true,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.graceDays ?? '-'}</div>
    ),
  },
  {
    accessorKey: 'graceValue',
    meta: {
      columnVisibilityName: InvoiceConfig.fields.graceValue.verboseName,
      initiallyHidden: true,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.graceValue ?? '-'}</div>
    ),
  },
  {
    accessorKey: 'reminderFee',
    meta: {
      columnVisibilityName: InvoiceConfig.fields.reminderFee.verboseName,
      initiallyHidden: true,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {toMoneyString(row.original.reminderFee)}
      </div>
    ),
  },
  {
    accessorKey: 'debtReminderFee',
    meta: {
      columnVisibilityName: InvoiceConfig.fields.debtReminderFee.verboseName,
      initiallyHidden: true,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {toMoneyString(row.original.debtReminderFee)}
      </div>
    ),
  },
  {
    accessorKey: 'reminderDistributionPushedUntil',
    meta: {
      columnVisibilityName:
        InvoiceConfig.fields.reminderDistributionPushedUntil.verboseName,
      initiallyHidden: true,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {toReadableDate(row.original.reminderDistributionPushedUntil)}
      </div>
    ),
  },
  {
    accessorKey: 'debtDistributionPushedUntil',
    meta: {
      columnVisibilityName:
        InvoiceConfig.fields.debtDistributionPushedUntil.verboseName,
      initiallyHidden: true,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {toReadableDate(row.original.debtDistributionPushedUntil)}
      </div>
    ),
  },
  {
    accessorKey: 'autoAttestDebtInvoices',
    meta: {
      columnVisibilityName:
        InvoiceConfig.fields.autoAttestDebtInvoices.verboseName,
      initiallyHidden: true,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {row.original.autoAttestDebtInvoices ? 'Ja' : 'Nej'}
      </div>
    ),
  },
  {
    accessorKey: 'automaticDebtInvoicingPaused',
    meta: {
      columnVisibilityName:
        InvoiceConfig.fields.automaticDebtInvoicingPaused.verboseName,
      initiallyHidden: true,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {row.original.automaticDebtInvoicingPaused ? 'Ja' : 'Nej'}
      </div>
    ),
  },
  {
    accessorKey: 'usedAccountConfig',
    enableSorting: false,
    meta: {
      columnVisibilityName: InvoiceConfig.fields.usedAccountConfig.verboseName,
      initiallyHidden: true,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <DropdownList
        ids={[row.original.usedAccountConfig?.id]}
        initialData={row.original.usedAccountConfig}
        detailModalLinkUrl={() =>
          Urls.settings.modules.invoicing.accounting.general
        }
        config={BookkeepingAccountsConfigConfig}
        fields={['id']}
      />
    ),
  },

  {
    accessorKey: 'tags',
    enableSorting: false,
    meta: {
      columnVisibilityName: InvoiceConfig.fields.tags.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span className='flex gap-2'>
        <TableTags
          tags={row.original.tags}
          modelName={InvoiceConfig.modelName}
        />
      </span>
    ),
  },

  {
    id: 'actions',
    enableSorting: false,
    enableHiding: false,
    cell: ({ row }) => {
      return (
        <TableActions
          instance={row.original}
          config={InvoiceConfig}
          disableEdit={!!row.original.attestedDate}
          disableDelete={!!row.original.attestedDate}
          overrideEdit={(router) =>
            router.push(Urls.invoicing.handle(row.original.id))
          }
          title={row.original.customId}
        />
      );
    },
  },
];
