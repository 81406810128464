import {
  defaultRelationFilter,
  type IFilter,
} from '@/components/table-filters/constants';
import {
  IndustrialPremisesConfig,
  IndustrialPremisesContractConfig,
} from '@pigello/pigello-matrix';
import baseFilters, {
  baseGlobalRealEstatesFilter,
  baseTagFilter,
} from '../../filters';
import baseContractFilters from '../components/base-contract-filters';

const industrialPremisesContractFilters: IFilter[] = [
  {
    title: IndustrialPremisesContractConfig.fields.space.verboseName,
    relationModelName: IndustrialPremisesConfig.modelName,
    externalFieldName:
      IndustrialPremisesContractConfig.fields.space.externalFieldName,
    ...defaultRelationFilter,
  },
  ...baseGlobalRealEstatesFilter,
  ...baseContractFilters,
  ...baseTagFilter,
  ...baseFilters,
];

export default industrialPremisesContractFilters;
