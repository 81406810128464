import { useConfig } from '@/hooks/useConfig';
import { deleteGenericInstance } from '@/requests/deleting';
import { useDeleteGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, GenericMutateOptions } from '@/requests/types';
import { getDisplayValue, type ModelName } from '@pigello/pigello-matrix';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../ui/alert-dialog';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Loader } from '../ui/loader';

export default function DeleteModal({
  modelName,
  id,
  options,
  title,
  information,
  setOpen,
  onSuccess,
  onError,
  noCheck,
  noConfirm,
}: {
  modelName: ModelName;
  id: string;
  options?: GenericMutateOptions;
  title?: string;
  information?: string | React.ReactNode;
  setOpen: (open: boolean) => void;
  onSuccess?: () => void;
  onError?: (error: ErrorResponse) => void;
  noCheck?: boolean;
  noConfirm?: boolean;
}) {
  const [loading, setLoading] = useState(false);
  const [randomNumbers, setRandomNumbers] = useState(0);
  const [randomSolve, setRandomSolve] = useState('');
  const queryClient = useQueryClient();
  const { config } = useConfig(modelName);
  useEffect(() => {
    const random = Math.floor(1000 + Math.random() * 9000);
    setRandomNumbers(random);
  }, []);

  const deleteMutation = useDeleteGenericInstance(
    () =>
      deleteGenericInstance({
        id,
        modelName,
        options,
      }),
    {
      onSuccess: (data) => {
        if ('mtMotivation' in data) {
          queryClient.invalidateQueries({
            queryKey: [config?.monitoringConfig],
          });
          if (config) {
            toast.warning('Kräver godkännande', {
              description: `${config?.verboseName} ${getDisplayValue(
                config,
                data
              )} kräver godkännande innan objektet kan raderas.`,
            });
          } else {
            toast.warning('Raderingen kräver godkännande');
          }
        }
        if (onSuccess) {
          onSuccess();
          setLoading(false);
          setOpen(false);
        } else {
          !('mtMotivation' in data) && toast.success('Raderingen lyckades');
          setLoading(false);
          setOpen(false);
        }
      },
      onError: (error) => {
        if (onError) {
          onError(error);
          setLoading(false);
        } else {
          toast.error('Raderingen misslyckades');
          setLoading(false);
        }
      },
    },
    modelName
  );

  const onDelete = () => {
    setLoading(true);
    deleteMutation.mutate({
      id,
    });
  };

  useEffect(() => {
    if (noConfirm) {
      onDelete();
    }
  }, [noConfirm]);

  return (
    <AlertDialog open onOpenChange={setOpen}>
      <AlertDialogContent className='gap-3'>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {(title ?? noConfirm) ? 'Raderar...' : 'Bekräfta radering'}
          </AlertDialogTitle>
        </AlertDialogHeader>

        {!noConfirm && (
          <AlertDialogDescription>
            {information ?? 'Bekräfta att du vill radera objektet'}
          </AlertDialogDescription>
        )}
        {loading && <Loader />}
        {!loading && !noCheck && !noConfirm && (
          <>
            <div className='rounded border bg-muted p-4'>
              <div className='mb-4 flex items-center'>
                <span className='p text-muted-foreground'>
                  Fyll i dessa siffror för att bekräfta raderingen:{' '}
                </span>

                <div className='ml-2 rounded-sm bg-foreground p-1 text-background'>
                  <code>{randomNumbers}</code>
                </div>
              </div>

              <Input
                className='bg-background'
                placeholder='Fyll i siffrorna för att bekräfta'
                value={randomSolve}
                onChange={(e) => setRandomSolve(e.target.value)}
              />
            </div>
          </>
        )}

        {!noConfirm && (
          <AlertDialogFooter>
            <Button
              size='sm'
              type='button'
              variant='destructive'
              onClick={onDelete}
              disabled={
                !noCheck && String(randomSolve) !== String(randomNumbers)
              }
            >
              Bekräfta
            </Button>
            <Button
              size='sm'
              type='button'
              variant='outline'
              onClick={() => {
                setOpen(false);
              }}
            >
              Avbryt
            </Button>
          </AlertDialogFooter>
        )}
      </AlertDialogContent>
    </AlertDialog>
  );
}
