import {
  defaultNumberFilter,
  defaultRelationFilter,
  defaultSelectFilter,
  defaultStringFilter,
  type IFilter,
} from '@/components/table-filters/constants';
import {
  OutdoorAreaConfig,
  OutdoorSectionConfig,
  TenantConfig,
} from '@pigello/pigello-matrix';
import baseRevenueIncreaseFilters from '../contracts/components/base-revenue-increase-filters';
import baseFilters, {
  baseAddressFilters,
  baseBuildingFilter,
  baseGlobalRealEstatesFilter,
  baseSpaceStatusFilters,
  baseTagFilter,
} from '../filters';

const outdoorSectionFilters: IFilter[] = [
  ...baseSpaceStatusFilters,
  ...baseBuildingFilter,
  ...baseGlobalRealEstatesFilter,
  {
    title: OutdoorSectionConfig.fields.outdoorArea.verboseName,
    externalFieldName:
      OutdoorSectionConfig.fields.outdoorArea.externalFieldName,
    relationModelName: OutdoorAreaConfig.modelName,
    ...defaultRelationFilter,
  },
  {
    title: OutdoorSectionConfig.fields.category.verboseName,
    externalFieldName: OutdoorSectionConfig.fields.category.externalFieldName,
    choices: OutdoorSectionConfig.fields.category.choices,
    ...defaultSelectFilter,
  },
  {
    title: OutdoorSectionConfig.fields.popularName.verboseName,
    externalFieldName:
      OutdoorSectionConfig.fields.popularName.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: OutdoorSectionConfig.fields.customId.verboseName,
    externalFieldName: OutdoorSectionConfig.fields.customId.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: OutdoorSectionConfig.fields.usableArea.verboseName,
    externalFieldName: OutdoorSectionConfig.fields.usableArea.externalFieldName,
    ...defaultNumberFilter,
  },
  {
    title: OutdoorSectionConfig.fields.biArea.verboseName,
    externalFieldName: OutdoorSectionConfig.fields.biArea.externalFieldName,
    ...defaultNumberFilter,
  },

  {
    title: OutdoorSectionConfig.fields.tenant.verboseName,
    externalFieldName: OutdoorSectionConfig.fields.tenant.externalFieldName,
    relationModelName: TenantConfig.modelName,
    ...defaultRelationFilter,
  },
  {
    title: OutdoorSectionConfig.fields.biTenants.verboseName,
    externalFieldName: OutdoorSectionConfig.fields.biTenants.externalFieldName,
    relationModelName: TenantConfig.modelName,
    ...defaultRelationFilter,
  },
  ...baseRevenueIncreaseFilters,
  ...baseAddressFilters,
  ...baseTagFilter,
  ...baseFilters,
];

export default outdoorSectionFilters;
