import {
  defaultNumberFilter,
  defaultRelationFilter,
  defaultSelectFilter,
  defaultStringFilter,
  type IFilter,
} from '@/components/table-filters/constants';
import {
  ParkingLotConfig,
  ParkingSpotConfig,
  TenantConfig,
} from '@pigello/pigello-matrix';
import baseRevenueIncreaseFilters from '../contracts/components/base-revenue-increase-filters';
import baseFilters, {
  baseAddressFilters,
  baseBuildingFilter,
  baseGlobalRealEstatesFilter,
  baseSpaceStatusFilters,
  baseTagFilter,
} from '../filters';

const parkingSpotFilters: IFilter[] = [
  ...baseSpaceStatusFilters,
  ...baseBuildingFilter,
  ...baseGlobalRealEstatesFilter,
  {
    title: ParkingSpotConfig.fields.parkingLot.verboseName,
    externalFieldName: ParkingSpotConfig.fields.parkingLot.externalFieldName,
    relationModelName: ParkingLotConfig.modelName,
    ...defaultRelationFilter,
  },
  {
    title: ParkingSpotConfig.fields.category.verboseName,
    externalFieldName: ParkingSpotConfig.fields.category.externalFieldName,
    choices: ParkingSpotConfig.fields.category.choices,
    ...defaultSelectFilter,
  },
  {
    title: ParkingSpotConfig.fields.popularName.verboseName,
    externalFieldName: ParkingSpotConfig.fields.popularName.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: ParkingSpotConfig.fields.customId.verboseName,
    externalFieldName: ParkingSpotConfig.fields.customId.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: ParkingSpotConfig.fields.usableArea.verboseName,
    externalFieldName: ParkingSpotConfig.fields.usableArea.externalFieldName,
    ...defaultNumberFilter,
  },
  {
    title: ParkingSpotConfig.fields.biArea.verboseName,
    externalFieldName: ParkingSpotConfig.fields.biArea.externalFieldName,
    ...defaultNumberFilter,
  },

  {
    title: ParkingSpotConfig.fields.tenant.verboseName,
    externalFieldName: ParkingSpotConfig.fields.tenant.externalFieldName,
    relationModelName: TenantConfig.modelName,
    ...defaultRelationFilter,
  },
  {
    title: ParkingSpotConfig.fields.biTenants.verboseName,
    externalFieldName: ParkingSpotConfig.fields.biTenants.externalFieldName,
    relationModelName: TenantConfig.modelName,
    ...defaultRelationFilter,
  },
  ...baseRevenueIncreaseFilters,
  ...baseAddressFilters,
  ...baseTagFilter,
  ...baseFilters,
];

export default parkingSpotFilters;
