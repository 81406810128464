import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { cn, ObjectEntries, ObjectValues } from '@/lib/utils';
import type { BaseInstance } from '@pigello/pigello-matrix';
import { useFormContext, useWatch, type Path } from 'react-hook-form';
import type { FieldProps } from '../types';
import { DescriptionTooltip } from './description-tooltip';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import type { OrderedFields } from '@/hooks/useForm/types';
import { useEffect, useState } from 'react';

const PRIVATE_TENANT_BANKS = {
  'Danske Bank': 'OEB',
  SEB: 'SEB',
  Handelsbanken: 'SHB',
  Skandiabanken: 'SKB',
  Swedbank: 'FSPA',
  Nordea: 'NB',
  'Länsförsäk.bank': 'LFB',
  'Sparbanken Öresund': 'FINN',
  'ICA banken': 'ICA',
  'Sparbanken Syd': 'SYD',
  SBAB: 'SBAB',
  Ålandsbanken: 'AAB',
} as const;

const COMPANY_TENANT_BANKS = {
  'Danske Bank': 'DBF',
  SEB: 'SEBF',
  Handelsbanken: 'SHBF',
  Nordea: 'NBF',
} as const;

/*
 * fmi format for companies:
 * 00orgNumber.bankNumber.SE
 *
 * fmi format for private tennants:
 * personalNumber.bankNumber.SE
 * */
function getBankFromFMI(fmi: string) {
  return fmi.split('.').at(1) ?? '';
}

function createFMI(bank: string, identifier: string, isCompany: boolean) {
  const parsedBank = bank || 'XXX';
  const parsedIdentifier =
    identifier || (isCompany ? 'XXXXXXXXXX' : 'XXXXXXXXXXXX');

  if (isCompany) return `00${parsedIdentifier}.${parsedBank}.SE`;
  return `${parsedIdentifier}.${parsedBank}.SE`;
}

export function FMIField<Instance extends BaseInstance = BaseInstance>({
  formField,
  name,
  placeholder,
  label,
  description,
  horizontal,
  className,
  disabled,
  overrideRequired,
  canHandleField,
  orderedFields,
}: FieldProps<Instance> & { orderedFields: OrderedFields<string, Instance> }) {
  const formContext = useFormContext();

  const isCompany = Boolean(
    ObjectValues(orderedFields).find((value) =>
      value.find((innerValue) => innerValue.name === 'corporateName')
    )
  );

  const identifier = useWatch({ name: isCompany ? 'orgNo' : 'ssn' });
  const [selectValue, setSelectValue] = useState<string>(
    getBankFromFMI(formContext.getValues(formField.name.toString()) ?? '')
  );

  useEffect(() => {
    formContext.setValue(
      formField.name.toString(),
      selectValue === '' ? '' : createFMI(selectValue, identifier, isCompany),
      {
        shouldDirty: true,
      }
    );
  }, [selectValue, identifier, formContext, formField.name, isCompany]);

  return (
    <FormField
      control={formField.control}
      rules={{
        required:
          (overrideRequired != null ? overrideRequired : formField.required) &&
          'Detta fält är obligatoriskt',
        validate: () => {
          if (selectValue) {
            if (!identifier) {
              return (isCompany ? 'Organisationsnummer' : 'Personnummer') +
                ' saknas, lägg till ' +
                isCompany
                ? 'Organisationsnummer'
                : 'Personnummer' + ' eller rensa fältet.';
            }
          }
          return true;
        },
      }}
      name={name as Path<Instance>}
      render={({ field }) => {
        const SelectInput = () => (
          <Select
            value={selectValue}
            disabled={disabled ?? field.disabled ?? !canHandleField}
            onValueChange={(value) =>
              setSelectValue(value === 'clear' ? '' : value)
            }
          >
            <SelectTrigger className='min-h-10 w-full'>
              <SelectValue placeholder={placeholder ?? 'Välj Bank'} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={'clear'} key={'id'} className='font-bold'>
                {placeholder ?? 'Välj Bank'}
              </SelectItem>
              {ObjectEntries(
                isCompany ? COMPANY_TENANT_BANKS : PRIVATE_TENANT_BANKS
              ).map(([key, value]) => (
                <SelectItem value={value} key={value}>
                  {key}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        );

        if (horizontal)
          return (
            <FormItem
              className={cn('flex items-center justify-between', className)}
            >
              <div className='w-1/2 shrink-0'>
                <div className='flex items-center'>
                  <FormLabel>
                    {label ?? formField.label}
                    {(overrideRequired != null
                      ? overrideRequired
                      : formField.required) && <span>*</span>}
                  </FormLabel>

                  {(description || formField.description) && (
                    <DescriptionTooltip
                      description={description ?? formField.description}
                    />
                  )}
                </div>
                <FormMessage />
                <FormDescription>{`Kräver giltigt ${isCompany ? 'org.nr' : 'pers.nr'}, fylls i separat fällt. ${field.value && `(${field.value})`}`}</FormDescription>
              </div>
              <FormControl>
                <SelectInput />
              </FormControl>
            </FormItem>
          );
        return (
          <FormItem className={className}>
            <div className='flex items-center'>
              <FormLabel>
                {'Välj bank för fakturamottagaridentitet '}
                {(overrideRequired != null
                  ? overrideRequired
                  : formField.required) && <span>*</span>}
              </FormLabel>
              {(description || formField.description) && (
                <DescriptionTooltip
                  description={description ?? formField.description}
                />
              )}
            </div>
            <FormControl>
              <SelectInput />
            </FormControl>
            <FormMessage />
            <FormDescription className='whitespace-pre-wrap'>{`Kräver giltigt ${isCompany ? 'org.nr' : 'pers.nr'}, fylls i separat fällt. ${
              field.value && `\n(fmi: ${field.value})`
            }`}</FormDescription>
          </FormItem>
        );
      }}
    />
  );
}
