 import type { Invoice } from '@pigello/pigello-matrix';

import { InvoiceConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutateGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, mutateGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { mutateGenericInstance } from '@/requests/mutating';

export const mutateInvoice = async (
  options: mutateGenericInstanceProps<Invoice>
) => {
  return mutateGenericInstance<Invoice>({
    ...options,
    config: InvoiceConfig,
  });
};

export const useMutateInvoice = (
  options: UseMutationOptions<Invoice, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useMutateGenericInstance<Invoice>(mutateInvoice, options, 'invoice');