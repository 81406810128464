'use client';

import { cn } from '@/lib/utils';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import {
  PaymentTermsSettingConfig,
  type PaymentTermsSetting,
} from '@pigello/pigello-matrix';
import Dot from './dot';
import type { RelationFieldProps } from './relation-item-map';

export const PaymentTermsSettingItem: RelationFieldProps<PaymentTermsSetting> =
  {
    nested: getRelationFieldNames(PaymentTermsSettingConfig),
    columns: [],
    renderItem: (item, isRelationField) => ({
      title: item.name,
      descriptionItems: (
        <div
          className={cn(
            isRelationField ? 'grid w-full' : 'flex flex-row items-center'
          )}
        >
          <div className='font-medium'>
            Gäller för {item.company ? item.company.name : 'organisationen'}
          </div>

          {!isRelationField && <Dot />}
          <div
            className={cn(
              'flex w-full items-center',
              isRelationField && 'justify-between'
            )}
          >
            {!isRelationField && <Dot />}
            <span> {item.customId ?? 'Inget objekts-id angivet'}</span>
          </div>
        </div>
      ),
    }),
  };
