import brfApartmentRevenueRowFilters from '@/components/detail/brf-apartment/components/brf-debit-filters';
import {
  calculateRevenueRowStatus,
  revenueRowStatusColors,
  revenueRowStatusLabels,
} from '@/components/detail/revenue/utils';
import { Badge } from '@/components/ui/badge';
import { toMoneyString } from '@/lib/utils';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import {
  ApartmentRevenueRowConfig,
  type ApartmentRevenueRow,
} from '@pigello/pigello-matrix';
import Dot from './dot';
import type { RelationFieldProps } from './relation-item-map';

export const RevenueRowItem: RelationFieldProps<ApartmentRevenueRow> = {
  filters: brfApartmentRevenueRowFilters,
  columns: [],
  nested: getRelationFieldNames(ApartmentRevenueRowConfig),
  renderItem: (item) => {
    const status = calculateRevenueRowStatus(item);

    return {
      title: (
        <>
          {item?.title}{' '}
          <Badge variant={revenueRowStatusColors[status]}>
            {revenueRowStatusLabels[status]}
          </Badge>
        </>
      ),
      descriptionItems: (
        <div className='flex w-full items-center'>
          <span className='font-medium'>
            {toMoneyString(item.unitAmount * (item?.unitPrice ?? 0))}/mån
          </span>
          <Dot />
          <span className='font-medium'>
            {item.startDate ?? 'Från avtalets början'} -{' '}
            {item.endDate ?? 'Tillsvidare'}
          </span>
          <Dot />
          <span className='font-medium'>{item.article?.name}</span>
        </div>
      ),
    };
  },
};
