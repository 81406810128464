'use client';

import indexationTableFilters from '@/app/dashboard/revenue/indexation/tables/filters';
import { cn } from '@/lib/utils';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import {
  IndexationSettingConfig,
  type IndexationSetting,
} from '@pigello/pigello-matrix';
import Dot from './dot';
import type { RelationFieldProps } from './relation-item-map';

export const IndexationSettingItem: RelationFieldProps<IndexationSetting> = {
  nested: getRelationFieldNames(IndexationSettingConfig),
  filters: indexationTableFilters,
  columns: [],
  renderItem: (item, isRelationField) => ({
    title: item?.name,
    descriptionItems: (
      <div
        className={cn(
          'flex w-full items-center',
          isRelationField && 'justify-between'
        )}
      >
        {!isRelationField && <Dot />}
        <span>{item.customId ?? 'Inget ID angett'}</span>
      </div>
    ),
  }),
};
