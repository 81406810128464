import {
  defaultStringFilter,
  type IFilter,
} from '@/components/table-filters/constants';
import { ExternalClientConfig } from '@pigello/pigello-matrix';
import baseFilters, { baseTagFilter } from '../filters';

const externalClientFilters: IFilter[] = [
  {
    title: ExternalClientConfig.fields.name.verboseName,
    externalFieldName: ExternalClientConfig.fields.name.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: ExternalClientConfig.fields.orgNo.verboseName,
    externalFieldName: ExternalClientConfig.fields.orgNo.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: ExternalClientConfig.fields.invoiceEmail.verboseName,
    externalFieldName:
      ExternalClientConfig.fields.invoiceEmail.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: ExternalClientConfig.fields.customId.verboseName,
    externalFieldName: ExternalClientConfig.fields.customId.externalFieldName,
    ...defaultStringFilter,
  },

  {
    title: `${ExternalClientConfig.fields.visitingAddress.verboseName} - Gatunamn`,
    externalFieldName: `${ExternalClientConfig.fields.visitingAddress.externalFieldName}__street`,
    ...defaultStringFilter,
  },
  {
    title: `${ExternalClientConfig.fields.visitingAddress.verboseName} - Stad`,
    externalFieldName: `${ExternalClientConfig.fields.visitingAddress.externalFieldName}__city`,
    ...defaultStringFilter,
  },
  {
    title: `${ExternalClientConfig.fields.visitingAddress.verboseName} - Gatunummer`,
    externalFieldName: `${ExternalClientConfig.fields.visitingAddress.externalFieldName}__street_number`,
    ...defaultStringFilter,
  },
  {
    title: `${ExternalClientConfig.fields.visitingAddress.verboseName} - Land`,
    externalFieldName: `${ExternalClientConfig.fields.visitingAddress.externalFieldName}__country`,
    ...defaultStringFilter,
  },
  {
    title: `${ExternalClientConfig.fields.invoiceAddress.verboseName} - Gatunamn`,
    externalFieldName: `${ExternalClientConfig.fields.invoiceAddress.externalFieldName}__street`,
    ...defaultStringFilter,
  },
  {
    title: `${ExternalClientConfig.fields.invoiceAddress.verboseName} - Stad`,
    externalFieldName: `${ExternalClientConfig.fields.invoiceAddress.externalFieldName}__city`,
    ...defaultStringFilter,
  },
  {
    title: `${ExternalClientConfig.fields.invoiceAddress.verboseName} - Gatunummer`,
    externalFieldName: `${ExternalClientConfig.fields.invoiceAddress.externalFieldName}__street_number`,
    ...defaultStringFilter,
  },
  {
    title: `${ExternalClientConfig.fields.invoiceAddress.verboseName} - Land`,
    externalFieldName: `${ExternalClientConfig.fields.invoiceAddress.externalFieldName}__country`,
    ...defaultStringFilter,
  },

  ...baseTagFilter,
  ...baseFilters,
];

export default externalClientFilters;
