'use client';

import {
  getChangeFileNameModalContent,
  type ChangeFileNameModalContentData,
} from '@/app/(filehandling)/components/change-file-name-modal';
import {
  getCreateFileModalContent,
  type CreateFileModalContentData,
} from '@/app/(filehandling)/components/create-file-modal';
import {
  getCreateFolderModalContent,
  type CreateFolderModalContentData,
} from '@/app/(filehandling)/components/create-folder-modal';
import {
  getEditFolderBasicSettingsModalContent,
  type EditFolderBasicSettingsModalContentData,
} from '@/app/(filehandling)/components/edit-folder-basic-settings-modal';
import type { FilePermissionsModalContentData } from '@/app/(filehandling)/components/file-permissions-modal';
import { getFilePermissionsModalContent } from '@/app/(filehandling)/components/file-permissions-modal';
import {
  getFolderPermissionsModalContent,
  type FolderPermissionsModalContentData,
} from '@/app/(filehandling)/components/folder-permissions-modal';
import {
  getImagePreviewModalContent,
  type ImagePreviewModalContentData,
} from '@/app/(filehandling)/components/image-preview-modal';
import {
  getMoveFileModalContent,
  type MoveFileModalContentData,
} from '@/app/(filehandling)/components/move-file-modal';
import {
  getMoveFolderModalContent,
  type MoveFolderModalContentData,
} from '@/app/(filehandling)/components/move-folder-modal';
import {
  getMatchPaymentContent,
  type MatchPaymentContentData,
} from '@/app/dashboard/invoicing/payments/components/matched-payment-modal';
import {
  getInvoicePreviewInvoiceForMassgenerationContent,
  type InvoicePreviewForMassgenerationContentData,
} from '@/app/invoicing/generate/preview-invoice-for-massgeneration-modal';
import type { CustomerColumnsModalContentData } from '@/components/detail/components/customercolumns/customer-columns-modal';
import { getCustomerColumnsModalContent } from '@/components/detail/components/customercolumns/customer-columns-modal';
import type {
  NoteModalContentData,
  NoteModalResponse,
} from '@/components/detail/components/notes/noteForm';
import { getNoteModalContent } from '@/components/detail/components/notes/noteForm';
import {
  getInvoiceAttachmentContent,
  type InvoiceAttachmentContentData,
} from '@/components/detail/invoice/components/attachment-modal';
import {
  getCreateManualPaymentContent,
  type CreateManualPaymentContentData,
} from '@/components/detail/invoice/components/create-manual-payment-modal';
import {
  getSendDebtInvoiceContent,
  type SendDebtInvoiceContentData,
} from '@/components/detail/invoice/components/send-debt-invoice-modal';
import {
  getSendInvoiceContent,
  type SendInvoiceContentData,
} from '@/components/detail/invoice/components/send-invoice-modal';
import {
  getCancelCancelledContractSignContractModalContent,
  type CancelCancelledContractModalContentData,
} from '@/components/detail/shared/contract/cancel-cancelled-contract-modal';
import type { CancelContractModalContentData } from '@/components/detail/shared/contract/cancel-modal';
import { getCancelContractModalContent } from '@/components/detail/shared/contract/cancel-modal';
import {
  getConfirmCancelContractModalContent,
  type ConfirmCancelContractModalContentData,
} from '@/components/detail/shared/contract/confirm-cancel-modal';
import {
  getManualSignContractModalContent,
  type ManualSignContractModalContentData,
} from '@/components/detail/shared/contract/sign-contract-modal';
import {
  getDebtPreviewModalContent,
  type DebtPreviewContentData,
  type DebtPreviewModalResponse,
} from '@/components/detail/shared/invoicing/preview-debt-modal';
import {
  getInvoicePreviewModalContent,
  type InvoicePreviewContentData,
  type InvoicePreviewModalResponse,
} from '@/components/detail/shared/invoicing/preview-modal';
import {
  getAnonymizeModalContent,
  type AnonymizeModalContentData,
} from '@/components/detail/tenant/components/anonymize-tenant-modal';
import {
  getDeanonymizeModalContent,
  type DeanonymizeModalContentData,
} from '@/components/detail/tenant/components/deanonymize-tenant-modal';
import {
  getGenerateVacancyInvoicesModalContent,
  type GenerateVacancyInvoicesModalContentData,
} from '@/components/detail/vacancynotation/components/generate-modal';
import type { CreateSingleInstanceModalContentData } from '@/components/form/default-create-form/create-modal';
import { getCreateSingleInstanceModalContent } from '@/components/form/default-create-form/create-modal';
import type { UpdateSingleInstanceModalContentData } from '@/components/form/default-edit-form/edit-modal';
import { getUpdateSingleInstanceModalContent } from '@/components/form/default-edit-form/edit-modal';
import type { IndexationUsageModalContentData } from '@/components/indexation/indexation-usage-modal';
import { getIndexationUsageModalContent } from '@/components/indexation/indexation-usage-modal';
import type {
  ConfirmModalContentData,
  ConfirmModalResponse,
} from '@/components/ui/ConfirmModalContent';
import { getConfirmModalContent } from '@/components/ui/ConfirmModalContent';
import {
  getIsFormDirtyContent,
  type IsFormDirtyContentData,
} from '@/components/ui/is-form-dirty-modal';
import { ModalContext } from '@/providers/modal-provider';
import type { BaseInstance } from '@pigello/pigello-matrix';
import { useContext, useMemo } from 'react';
import type { ArchiveInstanceModalContentData } from './use-archive/use-archive-modal';
import { getArchiveInstanceModalContent } from './use-archive/use-archive-modal';

export const useModal = () => {
  const { openModal, isOpen } = useContext(ModalContext);

  if (!openModal) {
    throw new Error('Wrap consumer of useModal in ModalProvider');
  }

  const actions = useMemo(
    () => ({
      isOpen,
      openConfirmModal: (
        contentData: ConfirmModalContentData
      ): ConfirmModalResponse =>
        openModal({
          getModalContent: getConfirmModalContent,
          contentData,
        }),
      openNoteModal: (contentData: NoteModalContentData): NoteModalResponse =>
        openModal({
          getModalContent: getNoteModalContent,
          contentData,
        }),
      openCancelContractModal: (contentData: CancelContractModalContentData) =>
        openModal({
          getModalContent: getCancelContractModalContent,
          contentData,
        }),
      openPreviewInvoiceModal: (
        contentData: InvoicePreviewContentData
      ): InvoicePreviewModalResponse =>
        openModal({
          getModalContent: getInvoicePreviewModalContent,
          contentData,
        }),
      openInvoicePreviewInvoiceForMassgenerationModal: (
        contentData: InvoicePreviewForMassgenerationContentData
      ) =>
        openModal({
          getModalContent: getInvoicePreviewInvoiceForMassgenerationContent,
          contentData,
        }),
      openPreviewDebtModal: (
        contentData: DebtPreviewContentData
      ): DebtPreviewModalResponse =>
        openModal({
          getModalContent: getDebtPreviewModalContent,
          contentData,
        }),
      openIndexationUsageModal: (
        contentData: IndexationUsageModalContentData
      ) =>
        openModal({
          getModalContent: getIndexationUsageModalContent,
          contentData,
        }),
      openGenerateVacancyInvoicesModal: (
        contentData: GenerateVacancyInvoicesModalContentData
      ) =>
        openModal({
          getModalContent: getGenerateVacancyInvoicesModalContent,
          contentData,
        }),
      openCreateSingleInstanceModal: <Instance extends BaseInstance>(
        contentData: CreateSingleInstanceModalContentData<Instance>
      ) =>
        openModal({
          getModalContent: getCreateSingleInstanceModalContent,
          contentData,
        }),
      openUpdateSingleInstanceModal: <Instance extends BaseInstance>(
        contentData: UpdateSingleInstanceModalContentData<Instance>
      ) =>
        openModal({
          getModalContent: getUpdateSingleInstanceModalContent,
          contentData,
        }),
      openArchiveInstanceModal: <Instance extends BaseInstance>(
        contentData: ArchiveInstanceModalContentData<Instance>
      ) =>
        openModal({
          getModalContent: getArchiveInstanceModalContent,
          contentData,
        }),
      openCustomerColumns: <Instance extends BaseInstance>(
        contentData: CustomerColumnsModalContentData<Instance>
      ) =>
        openModal({
          getModalContent: getCustomerColumnsModalContent,
          contentData,
        }),
      openAnonymizeModal: (contentData: AnonymizeModalContentData) =>
        openModal({ contentData, getModalContent: getAnonymizeModalContent }),
      openDeanonymizeModal: (contentData: DeanonymizeModalContentData) =>
        openModal({ contentData, getModalContent: getDeanonymizeModalContent }),
      openSendInvoiceModal: (contentData: SendInvoiceContentData) => {
        openModal({ contentData, getModalContent: getSendInvoiceContent });
      },
      openSendDebtInvoiceModal: (contentData: SendDebtInvoiceContentData) => {
        openModal({ contentData, getModalContent: getSendDebtInvoiceContent });
      },
      openCreateManualPaymentModal: (
        contentData: CreateManualPaymentContentData
      ) => {
        openModal({
          contentData,
          getModalContent: getCreateManualPaymentContent,
        });
      },
      openHandleInvoiceAttachmentModal: (
        contentData: InvoiceAttachmentContentData
      ) => {
        openModal({
          contentData,
          getModalContent: getInvoiceAttachmentContent,
        });
      },
      openMatchPaymentModal: (contentData: MatchPaymentContentData) => {
        openModal({
          contentData,
          getModalContent: getMatchPaymentContent,
        });
      },
      openCreateFolderModal: (contentData: CreateFolderModalContentData) => {
        openModal({
          contentData,
          getModalContent: getCreateFolderModalContent,
        });
      },
      openCreateFileModal: (contentData: CreateFileModalContentData) => {
        openModal({
          contentData,
          getModalContent: getCreateFileModalContent,
        });
      },
      openChangeFileNameModal: (
        contentData: ChangeFileNameModalContentData
      ) => {
        openModal({
          contentData,
          getModalContent: getChangeFileNameModalContent,
        });
      },
      openEditFolderBasicSettingsModal: (
        contentData: EditFolderBasicSettingsModalContentData
      ) => {
        openModal({
          contentData,
          getModalContent: getEditFolderBasicSettingsModalContent,
        });
      },
      openFolderPermissionsModal: (
        contentData: FolderPermissionsModalContentData
      ) => {
        openModal({
          contentData,
          getModalContent: getFolderPermissionsModalContent,
        });
      },
      openFilePermissionsModal: (
        contentData: FilePermissionsModalContentData
      ) => {
        openModal({
          contentData,
          getModalContent: getFilePermissionsModalContent,
        });
      },
      openPreviewImageModal: (contentData: ImagePreviewModalContentData) => {
        openModal({
          contentData,
          getModalContent: getImagePreviewModalContent,
        });
      },
      openMoveFolderModal: (contentData: MoveFolderModalContentData) => {
        openModal({
          contentData,
          getModalContent: getMoveFolderModalContent,
        });
      },
      openMoveFileModal: (contentData: MoveFileModalContentData) => {
        openModal({
          contentData,
          getModalContent: getMoveFileModalContent,
        });
      },
      openIsFormDirtyModal: (contentData: IsFormDirtyContentData) => {
        openModal({
          contentData,
          getModalContent: getIsFormDirtyContent,
        });
      },
      openManualSignContractModal: <Instance extends BaseInstance>(
        contentData: ManualSignContractModalContentData<Instance>
      ) => {
        openModal({
          contentData,
          getModalContent: getManualSignContractModalContent,
        });
      },
      openConfirmCancelContractModal: (
        contentData: ConfirmCancelContractModalContentData
      ) => {
        openModal({
          contentData,
          getModalContent: getConfirmCancelContractModalContent,
        });
      },
      openCancelCancelledContractModal: <Instance extends BaseInstance>(
        contentData: CancelCancelledContractModalContentData<Instance>
      ) => {
        openModal({
          contentData,
          getModalContent: getCancelCancelledContractSignContractModalContent,
        });
      },
    }),
    [openModal, isOpen]
  );

  return actions;
};
