'use client';

import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header';
import { TableActions } from '@/components/data-table/table-actions';
import { TableTags } from '@/components/data-table/table-tags';
import { Checkbox } from '@/components/ui/checkbox';
import { StatusBadge } from '@/components/ui/statusBadge';
import { toReadableDate, toReadableDateTime } from '@/lib/dates';
import { Urls } from '@/lib/urls';
import { toWeekInterval } from '@/lib/utils';
import type {
  IndustrialPremises,
  IndustrialPremisesContract,
} from '@pigello/pigello-matrix';
import {
  IndustrialPremisesConfig,
  IndustrialPremisesContractConfig,
  TenantConfig,
  type Tenant,
} from '@pigello/pigello-matrix';
import type { ColumnDef } from '@tanstack/react-table';
import { DropdownList } from '../../components/dropdown-list';

export const columns: ColumnDef<IndustrialPremisesContract>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label='Select all'
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label='Select row'
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'status',
    meta: {
      columnVisibilityName:
        IndustrialPremisesContractConfig.fields.status.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      return (
        <StatusBadge type='contract' status={row.original.status as number} />
      );
    },
  },

  {
    accessorKey: 'customId',
    meta: {
      columnVisibilityName:
        IndustrialPremisesContractConfig.fields.customId.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.customId ?? '-'}</div>
    ),
  },
  {
    accessorKey: 'idNumber',
    meta: {
      columnVisibilityName:
        IndustrialPremisesContractConfig.fields.idNumber.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.idNumber}</div>
    ),
  },
  {
    accessorKey: 'tenant',
    enableSorting: false,
    meta: {
      columnVisibilityName:
        IndustrialPremisesContractConfig.fields.tenant.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <DropdownList
        ids={[row.original.tenant?.id]}
        initialData={row.original.tenant}
        detailModalLinkUrl={(id) => Urls.detail.tenant.overview(id)}
        config={TenantConfig}
        fields={['communicationName']}
      />
    ),
  },
  {
    accessorKey: 'biTenants',
    enableSorting: false,
    meta: {
      columnVisibilityName:
        IndustrialPremisesContractConfig.fields.biTenants.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <DropdownList<Tenant>
        ids={row.original.biTenants.map((bt) => bt.id)}
        initialData={row.original.biTenants[0]}
        detailModalLinkUrl={(id) => Urls.detail.tenant.overview(id)}
        config={TenantConfig}
        fields={['communicationName']}
      />
    ),
  },
  {
    accessorKey: 'space',
    enableSorting: false,
    meta: {
      columnVisibilityName:
        IndustrialPremisesContractConfig.fields.space.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <DropdownList<IndustrialPremises>
        ids={[row.original.space?.id]}
        initialData={row.original.space}
        detailModalLinkUrl={(id) => Urls.detail.industrialpremises.overview(id)}
        config={IndustrialPremisesConfig}
        fields={['popularName', 'customId']}
      />
    ),
  },
  {
    accessorKey: 'startDate',
    meta: {
      columnVisibilityName:
        IndustrialPremisesContractConfig.fields.startDate.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      if (!row.original.startDate) return <span>-</span>;
      return (
        <span className='whitespace-nowrap'>
          {toReadableDate(row.original.startDate)}
        </span>
      );
    },
  },
  {
    accessorKey: 'initialEndDate',
    meta: {
      columnVisibilityName:
        IndustrialPremisesContractConfig.fields.initialEndDate.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span className='whitespace-nowrap'>
        {toReadableDate(row.original.initialEndDate)}
      </span>
    ),
  },
  {
    accessorKey: 'cancellationTime',
    meta: {
      columnVisibilityName:
        IndustrialPremisesContractConfig.fields.cancellationTime.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      if (!row.original.cancellationTime) return <span>-</span>;
      return (
        <span className='whitespace-nowrap'>
          {toReadableDateTime(row.original.cancellationTime)}
        </span>
      );
    },
  },
  {
    accessorKey: 'lifetimeEndDate',
    meta: {
      columnVisibilityName:
        IndustrialPremisesContractConfig.fields.lifetimeEndDate.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      if (!row.original.lifetimeEndDate) return <span>-</span>;
      return (
        <span className='whitespace-nowrap'>
          {toReadableDate(row.original.lifetimeEndDate)}
        </span>
      );
    },
  },
  {
    accessorKey: 'signedTime',
    meta: {
      columnVisibilityName:
        IndustrialPremisesContractConfig.fields.signedTime.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      if (!row.original.signedTime) return <span>-</span>;
      return (
        <span className='whitespace-nowrap'>
          {toReadableDateTime(row.original.signedTime)}
        </span>
      );
    },
  },
  {
    accessorKey: 'cancellationSignedTime',
    meta: {
      columnVisibilityName:
        IndustrialPremisesContractConfig.fields.cancellationSignedTime
          .verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      if (!row.original.cancellationSignedTime) return <span>-</span>;
      return (
        <span className='whitespace-nowrap'>
          {toReadableDateTime(row.original.cancellationSignedTime)}
        </span>
      );
    },
  },
  {
    accessorKey: 'counterpartNotificationIntervalWeeks',
    meta: {
      columnVisibilityName:
        IndustrialPremisesContractConfig.fields
          .counterpartNotificationIntervalWeeks.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span>
        {toWeekInterval(row.original.counterpartNotificationIntervalWeeks)}
      </span>
    ),
  },
  {
    accessorKey: 'ownerNotificationIntervalWeeks',
    meta: {
      columnVisibilityName:
        IndustrialPremisesContractConfig.fields.ownerNotificationIntervalWeeks
          .verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span>{toWeekInterval(row.original.ownerNotificationIntervalWeeks)}</span>
    ),
  },
  {
    accessorKey: 'renewedTo',
    meta: {
      columnVisibilityName:
        IndustrialPremisesContractConfig.fields.renewedTo.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => <span>{toReadableDate(row.original.renewedTo)}</span>,
  },
  {
    accessorKey: 'renewIntervalWeeks',
    meta: {
      columnVisibilityName:
        IndustrialPremisesContractConfig.fields.renewIntervalWeeks.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span>{toWeekInterval(row.original.renewIntervalWeeks)}</span>
    ),
  },
  {
    accessorKey: 'renewIndefinetely',
    meta: {
      columnVisibilityName:
        IndustrialPremisesContractConfig.fields.renewIndefinetely.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span>{row.original.renewIndefinetely ? 'Ja' : 'Nej'}</span>
    ),
  },
  {
    accessorKey: 'counterpartWarningIntervalWeeks',
    meta: {
      columnVisibilityName:
        IndustrialPremisesContractConfig.fields.counterpartWarningIntervalWeeks
          .verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span>
        {toWeekInterval(row.original.counterpartWarningIntervalWeeks)}
      </span>
    ),
  },
  {
    accessorKey: 'ownerWarningIntervalWeeks',
    meta: {
      columnVisibilityName:
        IndustrialPremisesContractConfig.fields.ownerWarningIntervalWeeks
          .verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span>{toWeekInterval(row.original.ownerWarningIntervalWeeks)}</span>
    ),
  },

  {
    accessorKey: 'counterpartWarningDate',
    meta: {
      columnVisibilityName:
        IndustrialPremisesContractConfig.fields.counterpartWarningDate
          .verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span>{toReadableDate(row.original.counterpartWarningDate)}</span>
    ),
  },
  {
    accessorKey: 'ownerWarningDate',
    meta: {
      columnVisibilityName:
        IndustrialPremisesContractConfig.fields.ownerWarningDate.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span>{toReadableDate(row.original.ownerWarningDate)}</span>
    ),
  },
  {
    accessorKey: 'tags',
    enableSorting: false,
    meta: {
      columnVisibilityName:
        IndustrialPremisesContractConfig.fields.tags.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span className='flex gap-2'>
        <TableTags
          tags={row.original.tags}
          modelName={IndustrialPremisesContractConfig.modelName}
        />
      </span>
    ),
  },
  {
    id: 'actions',
    enableSorting: false,
    enableHiding: false,
    cell: ({ row }) => (
      <TableActions
        instance={row.original}
        config={IndustrialPremisesContractConfig}
        title={row.original.idNumber}
      />
    ),
  },
];
