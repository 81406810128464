'use client';

import { DateTimePicker } from '@/components/form/dynamic-field/components/date-field/date-time-picker';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import OverlayLoader from '@/components/ui/overlay-loader';
import { Switch } from '@/components/ui/switch';
import { useGetCookies } from '@/hooks/use-get-cookies';
import { handleFormErrors } from '@/hooks/useForm/utils';
import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import { postApi } from '@/requests/api';
import { BASE_BACKEND_URL } from '@/requests/constants';
import { XMarkIcon } from '@heroicons/react/16/solid';
import { parseDate } from '@internationalized/date';
import { VacancyInvoiceConfig, type ModelName } from '@pigello/pigello-matrix';
import { useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { toast } from 'sonner';

export type GenerateVacancyInvoicesModalContentData = {
  ids: string[];
  modelName: ModelName;
};

interface Props extends GetModalContentProps {
  closeModal: () => void;
  contentData: GenerateVacancyInvoicesModalContentData;
}

function GenerateVacancyInvoicesModal({ closeModal, contentData }: Props) {
  const { ids, modelName } = contentData;
  const [loading, setLoading] = useState(false);
  const { cookies } = useGetCookies();

  const qc = useQueryClient();
  const [attemptAutoAttest, setAttemptAutoAttest] = useState(false);
  const [periodStart, setPeriodStart] = useState(
    DateTime.now().minus({ month: 1 }).startOf('month').toFormat('yyyy-MM-dd')
  );
  const [periodEnd, setPeriodEnd] = useState(
    DateTime.now().minus({ month: 1 }).endOf('month').toFormat('yyyy-MM-dd')
  );

  const canSubmit = () => {
    return (
      !!periodStart &&
      !!periodEnd &&
      DateTime.fromISO(periodStart) < DateTime.fromISO(periodEnd)
    );
  };

  const onSubmit = async () => {
    setLoading(true);

    toast.promise(
      async () =>
        await postApi<
          {
            date: string;
            vacancyinvoice: { id: string }[];
            vacancyinvoicerow: { id: string }[];
          }[]
        >({
          url: `${BASE_BACKEND_URL}/accounting/vacancies/perform/${modelName}/${cookies?.organization_id}/`,
          body: JSON.stringify({
            ids: ids.map((id) => ({
              id,
            })),
            period_start: periodStart,
            period_end: periodEnd,
            attemptAutoAttest: attemptAutoAttest ? true : undefined,
          }),
        }),
      {
        loading: 'Genererar underlag...',
        success: (data) => {
          setLoading(false);

          const generatedCount = data
            .map((d) => d.vacancyinvoice)
            .flat().length;

          closeAndClear();
          qc.invalidateQueries({
            queryKey: [VacancyInvoiceConfig.modelName],
          });

          if (generatedCount === 0)
            return `Inga underlag genererades. Detta kan bero på att underlag för denna period redan är genererade, eller att det inte finns några vakansrader att boka under denna period.`;
          return `Genererade ${generatedCount} underlag`;
        },
        error: (err) => {
          setLoading(false);

          const field = handleFormErrors(err);
          return `Något gick fel: ${field}`;
        },
      }
    );
  };

  const closeAndClear = () => {
    closeModal();
  };

  return (
    <Dialog defaultOpen onOpenChange={() => closeAndClear}>
      <DialogContent size='sm' className={'p-0'}>
        {loading && <OverlayLoader />}
        <DialogHeader className='flex flex-row items-center justify-between space-y-0 border-b p-4'>
          <DialogTitle>Generera vakansunderlag för period</DialogTitle>
          <Button onClick={closeAndClear} variant={'outline'} size={'icon-sm'}>
            <XMarkIcon className='size-4' />
          </Button>
        </DialogHeader>

        <div className='grid grid-cols-2 gap-6 p-4'>
          {ids.length > 1 && (
            <div className='col-span-2 text-sm'>
              Generera underlag för <strong>{ids.length}</strong> valda
              vakansavtal.
            </div>
          )}
          <div className='col-span-2 text-sm'>
            Välj period som underlagen ska genereras för. Perioder som underlag
            redan har genererats för kommer ej att genereras på nytt.
          </div>
          <div className='grid gap-1'>
            <Label>Period från</Label>
            <DateTimePicker
              value={periodStart ? parseDate(periodStart) : null}
              onChange={(e) => {
                setPeriodStart(e?.toString() ?? null);
              }}
            />
          </div>
          <div className='grid gap-1'>
            <Label>Period till</Label>
            <DateTimePicker
              value={periodEnd ? parseDate(periodEnd) : null}
              onChange={(e) => {
                setPeriodEnd(e?.toString() ?? null);
              }}
            />
          </div>

          <div className='col-span-2 flex items-center justify-between'>
            <Label>Autoattestera genererade underlag</Label>
            <Switch
              checked={attemptAutoAttest}
              onCheckedChange={(val) => setAttemptAutoAttest(!!val)}
            />
          </div>
        </div>

        <DialogFooter>
          <Button
            type='button'
            variant='outline'
            onClick={() => closeAndClear()}
          >
            Avbryt
          </Button>
          <Button disabled={!canSubmit()} onClick={onSubmit}>
            Generera underlag
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export const getGenerateVacancyInvoicesModalContent = ({
  closeModal,
  contentData,
}: Props): ModalContent => {
  return {
    externalModalHandling: true,
    body: (
      <GenerateVacancyInvoicesModal
        closeModal={closeModal}
        contentData={contentData}
      />
    ),
  };
};
